:root {
  --border-color: #ececee;
  --hover-selection: #f4f3ff;
  --text-color: #8c8e90;
  --dark-text-color: #2a2a2a;
  --blue-nova: #334eff;
  --dark-text-info: #57595b;
  --white: white;
  --blue-status: #036;
  --dark-text-10: #57595b1a;
}

.w-checkbox {
  margin-bottom: 5px;
  padding-left: 20px;
  display: block;
}

.w-checkbox:before {
  content: " ";
  grid-area: 1 / 1 / 2 / 2;
  display: table;
}

.w-checkbox:after {
  content: " ";
  clear: both;
  grid-area: 1 / 1 / 2 / 2;
  display: table;
}

.w-checkbox-input {
  float: left;
  margin: 4px 0 0 -20px;
  line-height: normal;
}

.w-checkbox-input--inputType-custom {
  border: 1px solid #ccc;
  border-radius: 2px;
  width: 12px;
  height: 12px;
}

.w-checkbox-input--inputType-custom.w--redirected-checked {
  background-color: #3898ec;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-color: #3898ec;
}

.w-checkbox-input--inputType-custom.w--redirected-focus {
  box-shadow: 0 0 3px 1px #3898ec;
}

.text-span-9, .text-span {
  color: #1837ff;
}

.holder-form-footer {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  justify-content: space-between;
  align-items: center;
  max-width: 1100px;
  display: flex;
}

.holder-buttons {
  z-index: 99;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  margin-bottom: 70px;
  display: flex;
  position: relative;
}

.holder-buttons._0m {
  margin-bottom: 0;
}

.text-span-5 {
  color: #1837ff;
  font-weight: 600;
}

.image-28 {
  display: none;
}

._2nd-screen {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  justify-content: space-between;
  align-items: center;
  max-width: 1100px;
  margin-bottom: 160px;
  display: flex;
}

.text-block-7 {
  color: #181818;
  font-family: Figtree, sans-serif;
  font-size: 16px;
  line-height: 26px;
}

.functionality-4 {
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 220px;
  padding-top: 60px;
  display: flex;
}

.avantages-section {
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  max-width: 1100px;
  margin-bottom: 220px;
  display: flex;
}

.text-block-10 {
  color: #fff;
  text-align: center;
  max-width: 710px;
  margin-bottom: 40px;
  font-family: Figtree, sans-serif;
  font-size: 20px;
  line-height: 30px;
}

.holder-links-navbar {
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  display: flex;
}

.text-field {
  border: 1px solid #1837ff66;
  border-radius: 5px;
  min-width: 270px;
  height: 50px;
  margin-bottom: 0;
  padding-left: 15px;
  font-family: Figtree, sans-serif;
  font-size: 16px;
  line-height: 24px;
}

.holder-links-footer {
  grid-column-gap: 100px;
  grid-row-gap: 100px;
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
}

.choisir-nova {
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1100px;
  margin-bottom: 220px;
  display: flex;
}

.link-2 {
  color: #333;
  text-transform: uppercase;
  font-family: Archivo, sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 30px;
  text-decoration: none;
}

.holder-first-subtitle {
  background-image: linear-gradient(120deg, #8e9dff1a, #4a50ff1a);
  border: 1px solid #1837ff33;
  border-radius: 99px;
  justify-content: center;
  align-items: center;
  height: 30px;
  margin-bottom: 40px;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  box-shadow: inset 0 0 5.5px #adadad4d, 0 0 10px #1837ff1a;
}

.image-13 {
  height: 150px;
  position: absolute;
  inset: 160px 0% auto auto;
}

.gradien-div-images {
  background-image: linear-gradient(135deg, #8e9dff1a, #4a50ff1a);
  border: 1px solid #1837ff1a;
  border-radius: 15px;
  padding: 30px;
  box-shadow: 0 0 10px 2px #1837ff1a;
}

.gradien-div-images._1 {
  justify-content: flex-start;
  align-items: center;
  width: 50%;
  padding-top: 25px;
  padding-left: 0;
  display: flex;
}

.gradien-div-images._2 {
  width: 50%;
  padding: 15px 15px 20px;
}

.gradien-div-images._3 {
  width: 50%;
  padding: 15px;
}

.holder-navbar {
  padding-top: 20px;
  padding-left: 5%;
  padding-right: 5%;
}

.line-under-link {
  background-color: #1837ff;
  width: 0;
  height: 0;
  position: absolute;
  inset: auto auto 0% 0%;
}

.icon-corner-down {
  position: absolute;
  inset: auto 0% 0% auto;
}

.image-7 {
  position: absolute;
  inset: -20px auto auto -40px;
}

.functionaity-5 {
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.heading-3 {
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(90deg, #8e9dff, #4a50ff);
  -webkit-background-clip: text;
  background-clip: text;
  margin-top: 0;
  margin-bottom: 10px;
  font-family: Figtree, sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 30px;
}

.image-9 {
  width: 47.5%;
}

.text-block-4 {
  color: #181818;
  font-family: Figtree, sans-serif;
  font-size: 18px;
  line-height: 26px;
}

.image-6 {
  width: 50%;
}

.up-side-footer {
  background-color: #4a50ff08;
  border-top: 1px solid #1837ff1a;
  border-bottom: 1px solid #1837ff1a;
  justify-content: center;
  align-items: center;
  padding: 30px 5%;
  display: flex;
}

.image-8 {
  position: absolute;
  inset: -20px auto auto -40px;
}

.submit-button {
  background-image: linear-gradient(135deg, #8e9dff, #4a50ff);
  border-radius: 5px;
  padding: 10px 30px;
  font-family: Figtree, sans-serif;
  font-size: 20px;
  line-height: 30px;
}

.text-block-11 {
  color: #333;
  font-family: Figtree, sans-serif;
  font-size: 18px;
  line-height: 30px;
}

.image-25 {
  display: none;
}

.heading-6 {
  color: #181818;
  font-family: Archivo, sans-serif;
  font-size: 30px;
  font-weight: 500;
  line-height: 40px;
}

.functionality-3 {
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 150px;
  display: flex;
}

.image-10 {
  position: absolute;
  inset: -20px auto auto -30px;
}

.functionality-2 {
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 220px;
  display: flex;
  position: relative;
}

.footer {
  background-image: radial-gradient(circle, #4a50ff0f, #4a50ff1a);
  flex-flow: column;
  display: flex;
}

.site-mention {
  border-top: 1px solid #333;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1100px;
  padding-top: 10px;
  display: flex;
}

.text-block-5 {
  color: #181818;
  text-align: center;
  max-width: 655px;
  margin-bottom: 40px;
  font-family: Figtree, sans-serif;
  font-size: 28px;
  font-weight: 600;
  line-height: 40px;
}

.section-2 {
  background-color: #b5bfff1a;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  padding: 200px 5% 150px;
  display: flex;
  position: relative;
}

.gradien-cercle {
  filter: blur(70px);
  background-image: linear-gradient(#1837ff0f, #1837ff26);
  border-radius: 99px;
  width: 200px;
  height: 200px;
  position: absolute;
  inset: -90% 0% auto -90px;
}

.gradien-cercle.mob {
  display: none;
}

.gradien-cercle._2 {
  justify-content: center;
  align-items: center;
  display: flex;
  inset: 0% 0% auto auto;
}

.image-3 {
  width: 230px;
  position: absolute;
  inset: 250px auto 0% 0%;
}

.image {
  height: 40px;
  margin-right: 70px;
}

.heading-5 {
  color: #fff;
  text-align: center;
  max-width: 640px;
  margin-top: 0;
  margin-bottom: 20px;
  font-family: Archivo, sans-serif;
  font-size: 45px;
  font-weight: 500;
  line-height: 58px;
}

.heading-4 {
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(90deg, #8e9dff, #4a50ff);
  -webkit-background-clip: text;
  background-clip: text;
  margin-bottom: 10px;
  font-family: Figtree, sans-serif;
  font-size: 32px;
  font-weight: 500;
  line-height: 40px;
}

.box-holder-links {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  flex-flow: column;
  display: flex;
}

.heading-2 {
  color: #181818;
  max-width: 470px;
  margin-bottom: 30px;
  font-weight: 500;
}

.heading-2._2 {
  margin-bottom: 20px;
  font-family: Archivo, sans-serif;
  font-size: 37px;
}

.heading-2.nan {
  margin-bottom: 0;
  font-family: Archivo, sans-serif;
}

.heading-2.center {
  font-family: Archivo, sans-serif;
}

.image-26 {
  display: none;
}

.holder-title {
  margin-bottom: 100px;
  position: relative;
}

.holder-header {
  margin-bottom: 0;
  display: flex;
  position: relative;
}

.link-navbar {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-decoration: none;
  display: flex;
  position: relative;
}

.form-block {
  margin-bottom: 0;
}

.form-block.mobile {
  display: none;
}

.text-block-8 {
  color: #333;
  margin-bottom: 30px;
  font-family: Figtree, sans-serif;
  font-size: 20px;
  line-height: 30px;
}

.heading {
  z-index: 99;
  color: #181818;
  text-align: center;
  max-width: 672px;
  margin-bottom: 15px;
  font-family: Archivo, sans-serif;
  font-size: 45px;
  font-weight: 500;
  line-height: 58px;
  position: relative;
}

.heading._2 {
  max-width: 510px;
  margin-top: 0;
  margin-bottom: 0;
}

.image-14 {
  height: 150px;
  position: absolute;
  inset: 160px auto auto 0%;
}

.image-27 {
  display: none;
}

.image-2 {
  z-index: 99;
  width: 90%;
  max-width: 1100px;
  margin-bottom: -1px;
  padding-bottom: 0;
  position: relative;
}

.image-11 {
  width: 200px;
  margin-bottom: 20px;
}

.holder-brand-footer {
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 300px;
  display: flex;
}

.holder-heading-text {
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 50%;
  display: flex;
  position: relative;
}

.holder-heading-text.last {
  position: sticky;
  top: 40px;
}

.holder-heading-text.mobile {
  display: none;
}

.holder-all-footer {
  justify-content: space-between;
  width: 100%;
  max-width: 1100px;
  margin-bottom: 80px;
  display: flex;
}

.image-29 {
  display: none;
}

.image-17 {
  position: absolute;
  inset: 66% 0% auto auto;
}

.sponsor-section {
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 200px;
  display: flex;
}

.holder-image-sponsor {
  grid-column-gap: 60px;
  grid-row-gap: 60px;
  padding: 30px 50px;
  display: flex;
  position: relative;
}

.image-15 {
  height: 150px;
  position: absolute;
  inset: 700px auto auto 0%;
}

.title-screen {
  color: #181818;
  margin-bottom: 20px;
  font-family: Figtree, sans-serif;
  font-size: 30px;
  font-weight: 600;
  line-height: 40px;
}

.section {
  border-bottom: 1px solid #1837ff1a;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 60px;
  display: flex;
  position: relative;
}

.text-block-6 {
  color: #181818;
  font-family: Figtree, sans-serif;
  font-size: 18px;
  line-height: 27px;
}

.text-block-6.margin {
  margin-bottom: 40px;
}

.line-dot {
  margin-bottom: 20px;
  position: relative;
}

.text-block-3 {
  z-index: 99;
  color: #181818;
  text-align: center;
  max-width: 516px;
  margin-bottom: 40px;
  font-family: Figtree, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  position: relative;
}

.holder-box-horizontal {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  justify-content: space-between;
  align-items: stretch;
  display: flex;
}

.down-side-footer {
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding: 40px 5% 80px;
  display: flex;
}

.functionality-1 {
  z-index: 10;
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 220px;
  padding-top: 60px;
  display: flex;
  position: relative;
}

.image-5 {
  position: absolute;
  inset: auto 0% 0%;
}

.form {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.blue-line-design {
  background-color: #1837ff;
  border-radius: 99px;
  width: 30px;
  height: 5px;
  margin-bottom: 40px;
}

.icon-corner-up {
  position: absolute;
  inset: 0% auto auto 0%;
}

.image-4 {
  width: 230px;
  position: absolute;
  inset: 250px 0% 0% auto;
}

.section-3 {
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  padding: 190px 5% 200px;
  display: flex;
  position: relative;
}

.navbar-nova {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.holder-icon {
  background-image: linear-gradient(135deg, #8e9dff, #4a50ff);
  border: 1px solid #ffffff1a;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  margin-bottom: 15px;
  display: flex;
}

.avantage-box {
  background-image: linear-gradient(135deg, #8e9dff1a, #4a50ff1a);
  border: 1px solid #1837ff1a;
  border-radius: 15px;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px 20px 40px;
  display: flex;
}

.avantage-box.down {
  width: 33%;
}

.holder-gradien-design {
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 200px;
  display: flex;
  position: absolute;
  inset: -25% 0% auto auto;
}

.holder-gradien-design._3 {
  inset: -40% auto auto 0%;
}

.holder-gradien-design._2 {
  inset: -50% auto auto 15%;
}

.holder-boxes-advantages {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  flex-flow: column;
  margin-bottom: 60px;
  display: flex;
}

.text-block {
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(90deg, #8e9dff, #4a50ff);
  -webkit-background-clip: text;
  background-clip: text;
  margin-right: 8px;
  font-family: Figtree, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.number-screen {
  color: #565656;
  margin-bottom: 30px;
  font-family: Montserrat, sans-serif;
  font-size: 50px;
  font-weight: 300;
  line-height: 60px;
}

.last-section-cta {
  background-image: linear-gradient(135deg, #8e9dff, #4a50ff);
  border-radius: 20px;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1100px;
  padding-top: 100px;
  padding-bottom: 100px;
  display: flex;
  box-shadow: 0 0 28px #1837ff40;
}

.navbar {
  display: none;
}

.button {
  text-transform: uppercase;
  background-color: #1837ff;
  border-radius: 10px;
  padding: 11px 20px 9px;
  font-family: Figtree, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
}

.button.white {
  color: #1837ff;
  background-color: #fff;
  border: 1px solid #1837ff;
  font-weight: 500;
}

.button.white3 {
  color: #1837ff;
  background-color: #fff;
}

.button.white2 {
  background-color: #0000;
  border: 1px solid #fff;
}

._3rd-screen {
  max-width: 1100px;
}

.text-block-9 {
  color: #333;
  margin-bottom: 20px;
  font-family: Figtree, sans-serif;
  font-size: 18px;
  line-height: 30px;
}

.section-4 {
  justify-content: center;
  align-items: center;
  padding-top: 140px;
  padding-bottom: 100px;
  display: flex;
}

.rich-text-block {
  width: 90%;
}

.footer-header {
  text-transform: uppercase;
  margin-top: 0;
  font-family: Archivo, sans-serif;
  font-size: 20px;
  font-weight: 500;
}

.text-block-15 {
  color: #333;
  font-family: Archivo, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.footer-links {
  text-decoration: none;
}

.text-block-16 {
  color: #181818;
  text-transform: uppercase;
  font-family: Archivo, sans-serif;
  font-size: 15px;
  line-height: 25px;
}

.nova_holder-software {
  justify-content: space-between;
  width: 100%;
  display: flex;
}

.nova_menu-old {
  border-right: 1.5px solid var(--border-color);
  flex-flow: column;
  justify-content: space-between;
  min-width: 250px;
  max-width: 300px;
  min-height: 100vh;
  padding: 20px 16px;
  display: flex;
}

.nova_brand-close-old {
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: space-between;
  align-items: center;
  min-height: 70px;
  padding-top: 9px;
  display: flex;
}

.nova_brand-close-old.closed {
  z-index: 9;
  background-color: #fff;
  justify-content: center;
}

.nova_brand-closd {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
}

.nova_close-button {
  cursor: pointer;
  border: 1.5px solid #ececee;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  display: flex;
}

.nova_close-button:hover {
  background-color: var(--hover-selection);
}

.nova_close-button.small {
  position: absolute;
  bottom: 0%;
  left: auto;
  right: -16px;
}

.nova_close-button._36 {
  width: 36px;
  height: 36px;
}

.nova_up-part-menu {
  grid-column-gap: 6px;
  grid-row-gap: 6px;
  flex-flow: column;
  display: flex;
}

.nova_holder-fonctions {
  border-top: 1.5px solid var(--border-color);
  flex-flow: column;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: stretch;
  padding-top: 16px;
  padding-bottom: 6px;
  display: flex;
}

.nova_holder-fonctions._2 {
  border-top-width: 0;
  padding-top: 0;
}

.nova_holder-fonctions._3 {
  border-top-width: 0;
  border-bottom-width: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.nova_title-fonctions {
  color: var(--text-color);
  text-transform: uppercase;
  margin-bottom: 12px;
  padding-left: 12px;
  font-family: Archivo, sans-serif;
  line-height: 18px;
}

.nova_fontions {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  cursor: pointer;
  border-radius: 8px;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 12px 10px 16px;
  text-decoration: none;
  transition: border-radius .4s, background-color .2s;
  display: flex;
}

.nova_fontions:hover {
  background-color: var(--hover-selection);
  border-radius: 8px;
}

.nova_fontions.selected {
  background-color: var(--hover-selection);
}

.nova_fontions.center {
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
}

.nova_fontions.active {
  background-color: var(--hover-selection);
}

.nova_relative-box {
  position: relative;
}

.nova {
  width: 20px;
  height: 20px;
  position: relative;
  top: -2px;
}

.nova_absolut-img {
  color: var(--dark-text-color);
  display: none;
  position: absolute;
  inset: 0%;
}

.nova_text-fonctions {
  color: var(--dark-text-color);
  text-transform: capitalize;
  font-family: Archivo, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
}

.nova_text-fonctions.active {
  color: var(--blue-nova);
}

.nova_user-card {
  border: 1.5px solid var(--border-color);
  border-radius: 11px;
  justify-content: space-between;
  align-items: center;
  padding: 18px 16px;
  display: flex;
}

.nova_avatar-text {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.nova_holder-texts {
  grid-column-gap: 2px;
  grid-row-gap: 2px;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.nova_name-avatar {
  color: var(--dark-text-color);
  font-family: Archivo, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.nova_email-avatar {
  color: var(--text-color);
  font-family: Archivo, sans-serif;
  font-size: 11px;
  line-height: 18px;
}

.nova_dots-avatar {
  cursor: pointer;
}

.nova_crm {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  flex-flow: column;
  flex: 1;
  width: 100%;
  padding: 20px;
  display: flex;
}

.nova_crm.leads_sticky_view {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.nova_crm.leads_sticky_view.closed_menu, .nova_crm.menu_closed {
  padding-left: 30px;
}

.nova-leads-notifications-old {
  border-bottom: 1.5px solid var(--border-color);
  justify-content: space-between;
  align-items: center;
  padding-bottom: 14px;
  display: flex;
}

.nova_title-leads {
  color: var(--dark-text-color);
  text-transform: capitalize;
  font-family: Archivo, sans-serif;
  font-size: 24px;
  line-height: 34px;
}

.nova_buttons {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  display: flex;
}

.nova_button-notification {
  border: 1.5px solid var(--border-color);
  cursor: pointer;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  display: flex;
}

.nova_button-notification:hover {
  background-color: var(--hover-selection);
}

.nova_holder-num-leads {
  margin-bottom: 14px;
  padding-top: 16px;
  padding-bottom: 16px;
}

.nova_num-leads {
  text-transform: capitalize;
  font-size: 36px;
  line-height: 46px;
}

.nova_selection-leads {
  justify-content: flex-start;
  align-items: stretch;
  display: flex;
}

.nova_selection-leads.leads_sticky_view {
  margin-top: 16px;
}

.nova_selectors {
  border-bottom: 1.5px solid var(--border-color);
  color: var(--text-color);
  font-family: Archivo, sans-serif;
  font-weight: 500;
  line-height: 18px;
  text-decoration: none;
  transition: border .2s;
}

.nova_selectors:hover {
  border-bottom-color: var(--blue-nova);
}

.nova_selectors.icon:hover {
  border-bottom: 1.5px solid var(--blue-nova);
}

.nova_text-selector-old {
  border-bottom: 1.5px solid var(--border-color);
  color: var(--text-color);
  cursor: pointer;
  padding-bottom: 22px;
  font-family: Archivo, sans-serif;
  font-size: 14px;
  line-height: 18px;
  transition: color .2s, border .2s;
}

.nova_text-selector-old:hover {
  color: var(--blue-nova);
}

.nova_text-selector-old.active {
  border-bottom-color: var(--blue-nova);
  color: var(--blue-nova);
}

.nova_search-tool {
  z-index: 399;
  background-color: #fff;
  flex-flow: row;
  flex: 0 auto;
  justify-content: space-between;
  align-items: center;
  margin-top: 4px;
  margin-bottom: 4px;
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  position: sticky;
  top: 70px;
}

.nova_search-tool.leads_sticky_view {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 20px;
  padding-bottom: 20px;
}

.nova_search-input {
  border: 1px solid var(--border-color);
  border-radius: 8px;
  width: 30%;
  min-width: 210px;
  max-width: 300px;
  margin-bottom: 0;
  padding-left: 36px;
  font-family: Archivo, sans-serif;
  font-weight: 400;
  line-height: 18px;
  box-shadow: 0 1px 1px #57595b1a;
}

.nova_search-input.large {
  width: 42%;
  min-width: 280px;
  max-width: 400px;
}

.nova_search-bar {
  align-items: baseline;
  margin-bottom: 0;
  display: flex;
}

.nova_relative-input-search {
  position: relative;
}

.nova_search-button {
  justify-content: center;
  align-items: center;
  height: 40px;
  padding-left: 12px;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: 0%;
  right: auto;
}

.nova_holder-tools {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.nova_holder-side-to-side {
  border: 1px solid var(--border-color);
  background-color: #f9f9fd;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  height: 40px;
  padding: 4px;
  display: flex;
}

.nova_first-side {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  display: flex;
}

.nova_first-side.selected {
  border: 1px solid var(--border-color);
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 1px 2px #0000001a;
}

.nova_holder-dropdown {
  border: 1px solid var(--border-color);
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 0;
  position: relative;
}

.nova_holder-dropdown:hover {
  background-color: var(--hover-selection);
}

.dropdown {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  color: var(--dark-text-color);
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  padding: 11px 11px 11px 16px;
  display: flex;
}

.dropdown.active {
  background-color: var(--hover-selection);
  color: var(--blue-nova);
  border-radius: 8px;
}

.dropdown-toggle {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-content: space-between;
  align-items: center;
  padding: 11px 11px 11px 16px;
  display: flex;
}

.dropdown-toggle._2 {
  width: 98px;
}

.icon-2 {
  color: #8c8e90;
  margin-right: 12px;
}

.text-block-17 {
  font-family: Archivo, sans-serif;
  font-weight: 500;
  line-height: 18px;
}

.text-block-17.selected {
  color: var(--blue-nova);
}

.nova_holder-filters {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  border: 1px solid var(--border-color);
  color: var(--dark-text-color);
  cursor: pointer;
  background-color: #fff;
  border-radius: 8px;
  justify-content: flex-start;
  align-items: center;
  height: 40px;
  padding-left: 12px;
  padding-right: 16px;
  text-decoration: none;
  display: flex;
}

.nova_holder-filters:hover {
  background-color: var(--hover-selection);
}

.nova_holder-filters.active {
  background-color: var(--hover-selection);
  color: var(--blue-nova);
}

.text-block-18 {
  font-family: Archivo, sans-serif;
  font-weight: 500;
  line-height: 18px;
}

.nova_holder-crm-infoleads {
  border: 1px solid var(--border-color);
  border-radius: 8px;
  flex-flow: column;
  justify-content: space-between;
  align-items: stretch;
  display: flex;
}

.nova_up-bar-info {
  background-color: #f9f9fd;
  min-width: 1125px;
  height: 40px;
  display: flex;
}

.nova_slider-horizontal {
  border: 1px solid var(--border-color);
  overflow: scroll;
}

.nova_empty-box {
  border-right: 1px solid var(--border-color);
  height: 100%;
  padding: 18px;
}

.nova_holder-all-sector-lead {
  border-right: 1px solid var(--border-color);
}

.nova_holder-all-selector {
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 100%;
  margin-bottom: 0;
  display: flex;
}

.checkbox-label {
  display: none;
}

.checkbox {
  border: 1px solid var(--border-color);
  width: 16px;
  height: 16px;
  margin-top: 0;
  margin-left: 0;
}

.checkbox-field {
  margin-bottom: 0;
  padding-left: 0;
}

.nova_holder-infos {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  border-right: 1px solid var(--border-color);
  justify-content: flex-start;
  align-items: center;
  min-width: 222px;
  padding-left: 16px;
  display: flex;
}

.nova_holder-infos._2 {
  min-width: 154px;
}

.nova_holder-infos._3 {
  min-width: 182px;
}

.nova_holder-infos._4 {
  min-width: 96px;
}

.nova_holder-infos._5 {
  min-width: 150px;
}

.nova_holder-infos._6 {
  min-width: 97px;
}

.nova_holder-infos._7 {
  min-width: 100px;
}

.nova_text-up-leads {
  color: var(--dark-text-info);
  font-family: Archivo, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.nova_holder-vertical-leads {
  flex-flow: column;
  min-width: 1125px;
  display: flex;
  overflow: scroll;
}

.nova_lead-infos {
  border-bottom: 1px solid var(--border-color);
  min-width: 100%;
  height: 56px;
  transition: border .2s, background-color .2s;
  display: flex;
}

.nova_lead-infos:hover {
  border-left: 1.5px solid var(--blue-nova);
  background-color: #f9f9fd;
}

.nova_fontions-lead {
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 38px;
  display: flex;
}

.nova_selector-lead {
  width: 48px;
}

.nova_holder-image-lead {
  background-color: #1a8317;
  border-radius: 99px;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  display: flex;
  overflow: hidden;
}

.nova_text-info-leads {
  color: var(--dark-text-color);
  font-family: Archivo, sans-serif;
  font-weight: 500;
  line-height: 18px;
}

.nova_holder-lead-info {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  justify-content: flex-start;
  align-items: center;
  min-width: 222px;
  padding-left: 16px;
  display: flex;
}

.nova_holder-lead-info._2 {
  min-width: 154px;
}

.nova_holder-lead-info._3 {
  min-width: 182px;
}

.nova_holder-lead-info._4 {
  min-width: 96px;
}

.nova_holder-lead-info._5 {
  min-width: 150px;
}

.nova_holder-lead-info._6 {
  min-width: 97px;
}

.nova_holder-lead-info._7 {
  min-width: 100px;
}

.nova_status-type {
  grid-column-gap: 4px;
  grid-row-gap: 4px;
  border: 1px solid var(--white);
  cursor: pointer;
  background-color: #cce6ff;
  border-radius: 4px;
  justify-content: flex-start;
  align-items: center;
  height: 24px;
  padding-left: 6px;
  padding-right: 8px;
  display: flex;
}

.nova_status-type.nrp {
  background-color: #ffd4cc;
}

.nova_status-type.sans {
  background-color: #ffe5cc;
}

.nova_status-type.signe {
  background-color: #d4ffcc;
}

.nova_status-type.pas-interesse {
  background-color: #eef1f7;
}

.nova_status-type.rdvm {
  background-color: #ffccf7;
}

.nova_status-type.arappeler {
  background-color: #cff;
}

.nova_status-type.nul {
  background-color: #fff7cc;
}

.nova_status-type.interesse {
  background-color: #d4ccff;
}

.nova_status-type.rdvp {
  background-color: #ecf;
}

.nova_status-type.rdvh {
  background-color: #e5ffcc;
}

.nova_status-type.faux {
  background-color: #cce6ff;
}

.nova_status-type.no_button {
  padding-right: 6px;
}

.nova_status-type.all {
  background-color: #fcd;
}

.nova_status-type.noborder {
  border-width: 0;
}

.nova_dot-status {
  aspect-ratio: 1;
  background-color: #036;
  border-radius: 99px;
  width: 6px;
  height: 6px;
}

.nova_dot-status.nrp {
  background-color: #610;
}

.nova_dot-status.sans {
  background-color: #630;
}

.nova_dot-status.signe {
  background-color: #160;
}

.nova_dot-status.pas-interesse {
  background-color: #57595b;
}

.nova_dot-status.rdvm {
  background-color: #605;
}

.nova_dot-status.arappeler {
  background-color: #066;
}

.nova_dot-status.nul {
  background-color: #650;
}

.nova_dot-status.interesse {
  background-color: #106;
}

.nova_dot-status.rdvp {
  background-color: #406;
}

.nova_dot-status.rdvh {
  background-color: #360;
}

.nova_dot-status.faux {
  background-color: #036;
}

.nova_dot-status.all {
  background-color: #602;
}

.nova_status-text {
  color: var(--blue-status);
  white-space: nowrap;
  font-family: Archivo, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}

.nova_status-text.nrp {
  color: #610;
  text-transform: uppercase;
}

.nova_status-text.sans {
  color: #630;
}

.nova_status-text.signe {
  color: #160;
}

.nova_status-text.pas-interesse {
  color: #57595b;
}

.nova_status-text.rdvm {
  color: #605;
}

.nova_status-text.arappeler {
  color: #066;
}

.nova_status-text.nul {
  color: #650;
}

.nova_status-text.interesse {
  color: #106;
}

.nova_status-text.rdvp {
  color: #406;
}

.nova_status-text.rdvh {
  color: #360;
}

.nova_status-text.faux {
  color: var(--blue-status);
}

.nova_status-text.all {
  color: #602;
}

.nova_priority-type {
  color: #543d18;
  cursor: pointer;
  background-color: #ffeab0;
  border-radius: 99px;
  padding: 4px 12px;
  font-family: Archivo, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-decoration: none;
}

.nova_priority-type.high {
  color: #711414;
  background-color: #fdeaea;
}

.nova_priority-type.low {
  color: var(--blue-nova);
  cursor: pointer;
  background-color: #f4f3ff;
  font-family: Archivo, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}

.nova_priority-type.bar {
  border: 1px solid var(--border-color);
  color: var(--dark-text-color);
  background-color: #fff;
}

.nova_priority-text {
  color: #543d18;
  font-family: Archivo, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}

.nova_priority-text.high {
  color: #711414;
}

.nova_priority-text.low {
  color: #334eff;
}

.nova_down-part-leads-info {
  justify-content: space-between;
  align-items: center;
  height: 56px;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
}

.nova_text-number-pages {
  color: var(--dark-text-color);
  font-family: Archivo, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.nova_holder-page-sectors {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.nova_pass-page {
  grid-column-gap: 4px;
  grid-row-gap: 4px;
  cursor: pointer;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.nova_arrow {
  width: 8px;
  height: 12px;
}

.nova_arrow.rotated {
  transform: rotate(180deg);
}

.nova_prev {
  color: var(--text-color);
  font-family: Archivo, sans-serif;
  font-weight: 500;
}

.nova_holder-number-pages {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.nova_number-page {
  border: 1px solid var(--border-color);
  color: var(--dark-text-color);
  cursor: pointer;
  background-color: #fff;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  text-decoration: none;
  display: flex;
}

.nova_number-page.m {
  width: auto;
}

.nova_number-page.selected {
  border-top-color: var(--blue-nova);
  border-right-color: var(--blue-nova);
  border-bottom-color: var(--blue-nova);
  border-left-color: var(--blue-nova);
  background-color: var(--blue-nova);
  color: #fff;
}

.nova_number-page-text {
  color: var(--dark-text-color);
  text-align: center;
  padding: 3px 8px;
  font-family: Archivo, sans-serif;
  line-height: 14px;
}

.nova_number-page-text.selected {
  background-color: var(--blue-nova);
  color: #fff;
  justify-content: center;
  align-items: center;
  display: flex;
}

.text-block-19 {
  color: #fff;
  text-transform: uppercase;
  font-family: Archivo, sans-serif;
  font-size: 11px;
}

.nova_holder-small-details {
  grid-column-gap: 50px;
  grid-row-gap: 50px;
  flex-flow: column;
  justify-content: flex-start;
  align-items: stretch;
  display: flex;
}

.nova_total-leads {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  flex-flow: column;
  flex: auto;
  padding: 24px;
  display: flex;
}

.nova_holder-icon-fonctions {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.nova_holder-number-stats {
  justify-content: space-between;
  align-items: flex-end;
  display: flex;
}

.nova_number-total-leads {
  color: var(--dark-text-color);
  font-family: Archivo, sans-serif;
  font-size: 36px;
  font-weight: 500;
  line-height: 46px;
}

.nova_stats-total-leads {
  grid-column-gap: 2px;
  grid-row-gap: 2px;
  background-color: #eafdee;
  border-radius: 200px;
  padding: 4px 8px;
  display: flex;
}

.nova-text-stats {
  color: #147129;
  font-family: Archivo, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}

.nova_icon-text {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.nova_icon-text._12 {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
}

.nova_stats_title {
  color: var(--dark-text-info);
  font-family: Archivo, sans-serif;
  font-weight: 500;
  line-height: 18px;
}

.nova_stats_title.darkgrey {
  color: var(--dark-text-color);
}

.nova_status-modal {
  z-index: 99;
  grid-column-gap: 6px;
  grid-row-gap: 6px;
  border: 1px solid var(--border-color);
  background-color: #fff;
  border-radius: 8px;
  flex-flow: column;
  max-width: 420px;
  margin-top: 0;
  padding: 24px 16px;
  display: flex;
}

.nova_status-modal.static {
  position: static;
}

.nova_holder-status-modals {
  grid-column-gap: 6px;
  grid-row-gap: 6px;
  flex-flow: row;
  display: flex;
}

.nova_menu-closed {
  border-right: 1.5px solid var(--border-color);
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
  max-width: 88px;
  padding: 20px;
  display: flex;
  position: relative;
}

.nova_up-part-closed-menu {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  flex-flow: column;
  display: flex;
}

.nova_line-design {
  background-color: var(--border-color);
  width: 100%;
  height: 1.5px;
}

.nova_fonctions-menu-closed {
  cursor: pointer;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  display: flex;
}

.nova_fonctions-menu-closed:hover {
  background-color: #f4f3ff;
}

.nova_user-menu {
  background-color: #ff6565;
  border-radius: 99px;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 38px;
  display: flex;
}

.nova_name-user-in-icon {
  color: #fff;
  text-transform: uppercase;
  font-family: Archivo, sans-serif;
  font-weight: 700;
  line-height: 18px;
}

.nova_button-open-menu {
  z-index: 99;
  border: 1px solid var(--border-color);
  cursor: pointer;
  background-color: #fff;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  display: flex;
  position: absolute;
  left: auto;
  right: -16px;
}

.nova_button-open-menu:hover {
  background-color: var(--hover-selection);
}

.nova_image-opn-menu {
  transform: rotate(180deg);
}

.nova_holder-filter-selection {
  border: 1.5px solid var(--border-color);
  background-color: #fff;
  border-radius: 8px;
  flex-flow: column;
  width: 391px;
  display: flex;
}

.nova_up-filters {
  border-bottom: 1px solid var(--border-color);
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px;
  display: flex;
}

.text-block-21 {
  color: var(--dark-text-color);
  font-family: Archivo, sans-serif;
  font-weight: 500;
  line-height: 18px;
}

.nova_clear-filters {
  cursor: pointer;
  text-decoration: none;
}

.text-block-22 {
  color: var(--dark-text-info);
  font-family: Archivo, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}

.nova_middle-filters {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  flex-flow: column;
  padding: 16px;
  display: flex;
}

.text-block-23 {
  color: var(--text-color);
  font-family: Archivo, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}

.nova_holder-quick-filters {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.nova_quick-filters {
  grid-column-gap: 4px;
  grid-row-gap: 4px;
  border: 1px solid var(--border-color);
  border-radius: 200px;
  justify-content: flex-start;
  align-items: center;
  padding: 6px 12px;
  text-decoration: none;
  display: flex;
}

.nova_quick-filters.selected {
  background-color: #f4f3ff;
}

.text-block-24 {
  color: var(--dark-text-color);
  white-space: nowrap;
  font-family: Archivo, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.text-block-24.selected {
  color: var(--blue-nova);
}

.nova_2lines-filtees {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.nova_holder-last-updates {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  flex-flow: column;
  display: flex;
}

.nova_holder-title-icon {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.nova_icon-title {
  grid-column-gap: 4px;
  grid-row-gap: 4px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.text-block-25 {
  color: var(--text-color);
  font-family: Archivo, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}

.nova_updates-close {
  cursor: pointer;
}

.nova_holder-dropdown-filter {
  z-index: 99;
  margin-bottom: 0;
  position: relative;
}

.nova_holder-dropdown-filter.z1 {
  z-index: 100;
}

.nova_holder-dropdown-filter.z2 {
  z-index: 101;
}

.nova_holder-dropdown-filter.z3 {
  z-index: 102;
}

.nova_holder-dropdown-filter.z4 {
  z-index: 103;
}

.nova_dropdown-filter {
  width: 100%;
}

.nova_dropdown-filters {
  border: 1px solid var(--border-color);
  border-radius: 4px;
  width: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 16px;
}

.icon-3 {
  width: 14px;
  height: 14px;
  margin-right: 16px;
}

.nova_button-add-filter {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  background-color: var(--blue-nova);
  cursor: pointer;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  padding-top: 11px;
  padding-bottom: 11px;
  text-decoration: none;
  display: flex;
}

.text-block-26 {
  color: #fff;
  font-family: Archivo, sans-serif;
  font-weight: 500;
  line-height: 18px;
}

.nova_lead-card {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  border: 1.5px solid var(--border-color);
  background-color: #f6f8fa;
  border-radius: 8px;
  flex-flow: column;
  padding: 20px 20px 72px;
  display: flex;
  position: relative;
}

.nova_holder-user-fonctions-lead {
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
}

.nova_lead-user {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.nova_user-abreviation {
  background-color: #334eff;
  border-radius: 99px;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  display: flex;
}

.nova_user-abreviation.color1 {
  background-color: #147129;
}

.nova_user-abreviation.color2 {
  background-color: #ff6565;
}

.nova_user-abreviation.color3 {
  background-color: #ff802b;
}

.nova_user-abreviation.color4 {
  background-color: #036;
}

.nova_user-abreviation.color5 {
  background-color: #610;
}

.nova_user-abreviation.color6 {
  background-color: #605;
}

.nova_user-abreviation.color7 {
  background-color: #ffccf7;
}

.nova_user-abreviation.color8 {
  background-color: #ffb900;
}

.nova_user-abreviation.color9 {
  background-color: #51c889;
}

.nova_user-abreviation.color10 {
  background-color: #d4ffcc;
}

.nova_user-abreviation.color11 {
  background-color: #cce6ff;
}

.nova_user-abreviation.color12 {
  background-color: #cff;
}

.nova_user-abreviation.color13 {
  background-color: #d4ccff;
}

.nova_user-abreviation.color14 {
  background-color: #ffeab0;
}

.nova_user-abreviation.color15 {
  background-color: #fdeaea;
}

.nova_user-abreviation.color16 {
  background-color: #9747ff;
}

.nova_user-abreviation.color17 {
  background-color: #066;
}

.nova_user-abreviation._48 {
  width: 48px;
  height: 48px;
}

.nova_user-abreviation._40 {
  width: 40px;
  height: 40px;
}

.nova_user-abreviation._24b {
  width: 24px;
  height: 24px;
}

.nova_user-abreviation._24b.m-4 {
  margin-left: -4px;
}

.nova_user-abreviation._30 {
  width: 30px;
  height: 30px;
}

.nova_user-abreviation._30.purple {
  background-color: #9747ff;
}

.nova_user-abreviation._30.green {
  background-color: #147129;
}

.nova_user-abreviation._30.orange {
  background-color: #ff6565;
}

.nova_abvreviation-user {
  color: #fff;
  text-transform: uppercase;
  font-family: Archivo, sans-serif;
  font-size: 16px;
  font-weight: 500;
}

.nova_abvreviation-user.dark {
  color: var(--dark-text-color);
}

.nova_abvreviation-user._10 {
  font-size: 10px;
  line-height: 14px;
}

.nova_abvreviation-user._24 {
  font-size: 6.4px;
  line-height: 8px;
}

.nova_name-lead {
  color: var(--dark-text-color);
  text-transform: capitalize;
  font-family: Archivo, sans-serif;
  font-size: 16px;
  font-weight: 700;
}

.nova_holder-name-updates {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  flex-flow: column;
  justify-content: space-between;
  display: flex;
}

.nova_update-user {
  grid-column-gap: 4px;
  grid-row-gap: 4px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.nova_last-update-text {
  color: var(--text-color);
  font-family: Archivo, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}

.nova_last-update-text.black {
  color: var(--dark-text-color);
}

.nova_holder-fonctions-lead {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.nava_x-times-calls {
  grid-column-gap: 4px;
  grid-row-gap: 4px;
  background-color: var(--blue-nova);
  cursor: pointer;
  border: 1.5px solid #1837ff;
  border-radius: 8px;
  justify-content: flex-start;
  align-items: center;
  padding: 4px 8px 4px 4px;
  text-decoration: none;
  display: flex;
}

.nova_x-time-box {
  background-color: #667aff;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  display: flex;
}

.nova_x-calls-text {
  color: #fff;
  font-family: Archivo, sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
}

.nova_times-text {
  color: #fff;
  font-family: Archivo, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.nova_vertical-line-design {
  background-color: var(--border-color);
  width: 1.5px;
  height: 14px;
}

.nova_fontions-lead-card {
  border: 1.5px solid var(--border-color);
  cursor: pointer;
  background-color: #fff;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  display: flex;
}

.nova_fontions-lead-card:hover {
  background-color: var(--hover-selection);
}

.nova_holder-status-priority {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-content: space-between;
  align-items: stretch;
  display: flex;
}

.nova_status-lead {
  border: 1px solid var(--border-color);
  background-color: #fff;
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  padding: 12px 10px 12px 20px;
  display: flex;
}

.nova_status-lead.priority {
  padding-top: 8px;
  padding-bottom: 8px;
}

.nova_status-type-text {
  color: var(--dark-text-info);
  font-family: Archivo, sans-serif;
  font-size: 16px;
  font-weight: 500;
}

.nova_level-priority {
  border: 1px solid var(--border-color);
  background-color: #fff;
  border-radius: 99px;
  padding: 4px;
}

.nova_priorities {
  color: var(--dark-text-color);
  cursor: pointer;
  border-radius: 99px;
  padding: 4px 12px;
  font-family: Archivo, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-decoration: none;
  transition: background-color .2s;
}

.nova_priorities:hover {
  background-color: #f4f3ff;
}

.nova_priorities.active {
  background-color: var(--hover-selection);
  color: var(--blue-nova);
}

.nova_priorities.active.medium {
  color: #543d18;
  background-color: #ffeab0;
}

.nova_priorities.active.high {
  color: #711414;
  background-color: #fdeaea;
}

.nova_contact-info-edit-lead {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.text-block-28 {
  color: var(--dark-text-color);
  font-family: Archivo, sans-serif;
  font-size: 16px;
  font-weight: 500;
}

.nova_edit-contact {
  grid-column-gap: 4px;
  grid-row-gap: 4px;
  cursor: pointer;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.text-block-29 {
  grid-column-gap: 4px;
  grid-row-gap: 4px;
  color: var(--blue-nova);
  font-family: Archivo, sans-serif;
  font-size: 16px;
  font-weight: 500;
  display: flex;
}

.nova_holder-leads-informations {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
}

.nova_box-contact-info {
  background-color: #fff;
  border-radius: 8px;
  flex-flow: column;
  width: 33%;
  min-height: 220px;
  padding: 10px 15px;
  display: flex;
}

.nova_holder-info-lead {
  border-bottom: 1px solid var(--border-color);
  justify-content: flex-end;
  align-items: flex-end;
  height: 40px;
  display: flex;
  position: relative;
}

.nova_input-info-lead {
  color: var(--dark-text-color);
  text-align: right;
  border: 1px solid #0000;
  width: 100%;
  margin-bottom: 0;
  padding-left: 5px;
  padding-right: 5px;
  font-family: Archivo, sans-serif;
  font-size: 16px;
}

.nova_input-info-lead.last {
  border-style: none;
  border-width: 0;
  border-radius: 0;
  padding-bottom: 4px;
}

.nova_info-input {
  align-items: center;
  width: 100%;
  margin-bottom: 0;
  display: flex;
}

.nova_info-text {
  color: var(--text-color);
  font-family: Archivo, sans-serif;
  font-size: 16px;
  font-weight: 500;
}

.nova_info-text.size {
  min-width: 86px;
}

.nova_info-text.size2 {
  min-width: 87px;
}

.nova_info-text.size3 {
  min-width: 140px;
}

.nova_info-text.size4 {
  min-width: 118px;
}

.nova_info-text.size5 {
  min-width: 128px;
}

.nova_holder-text-info {
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  inset: 0% auto 0% 5px;
}

.nova_commentaire-lead {
  border: 1px solid var(--border-color);
  color: var(--dark-text-info);
  border-radius: 8px;
  margin-bottom: 0;
  padding: 19px 46px;
  font-family: Archivo, sans-serif;
  font-weight: 500;
}

.nova_form-commentaire-lead {
  margin-bottom: 0;
  position: absolute;
  inset: auto 0% 0%;
}

.nova_form-input {
  position: relative;
}

.nova_holer-img {
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 10px;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: 0%;
  right: auto;
}

.nova_link-button-lead {
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  position: absolute;
  inset: 0% 0% 0% auto;
}

.nova_priority-dropdown {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  border: 1px solid var(--border-color);
  background-color: #fff;
  border-radius: 8px;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 150px;
  padding: 16px;
  display: flex;
}

.nova_functions-per-lead {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  border: 1px solid var(--border-color);
  background-color: #fff;
  border-radius: 8px;
  flex-flow: column;
  max-width: 192px;
  padding: 12px 4px;
  display: flex;
}

.nova_links-functions {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 8px 8px 16px;
  text-decoration: none;
  transition: background-color .2s;
  display: flex;
}

.nova_links-functions:hover, .nova_links-functions.selected {
  background-color: #f9f9fd;
}

.nova_text-function {
  color: var(--dark-text-color);
  font-family: Archivo, sans-serif;
  font-weight: 500;
  line-height: 18px;
}

.image-35 {
  height: 30px;
}

.nova_menu_closed {
  border-right: 1.5px solid var(--border-color);
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
}

.nova_holder-software-grid {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  grid-template-rows: auto 1fr;
  grid-template-columns: minmax(auto, auto) minmax(200px, 1fr);
  grid-auto-columns: 1fr;
  justify-content: space-between;
  width: 100%;
  min-height: 100vh;
  display: grid;
}

.nova_holder-software-grid.chatbot {
  z-index: 1999;
  position: fixed;
  inset: 0%;
}

.nova-leads-notifications {
  z-index: 399;
  border-bottom: 1px solid var(--border-color);
  background-color: #fff;
  justify-content: space-between;
  align-items: center;
  margin-left: 20px;
  margin-right: 20px;
  padding-top: 9px;
  display: flex;
  position: sticky;
  top: 0;
}

.nova-leads-notifications.closed_menu, .nova-leads-notifications.menu_closed {
  margin-left: 30px;
}

.nova_brand {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
}

.nova_brand-cont {
  border-right: 1.5px solid var(--border-color);
  background-color: #fff;
  flex-flow: column;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: center;
  align-items: stretch;
  min-width: 275px;
  min-height: 70px;
  display: flex;
  position: sticky;
  top: 0;
}

.nova_brand-cont.close {
  min-width: 85px;
}

.nova_brand-cont.small {
  min-width: auto;
  max-width: 88px;
}

.nova_menu {
  border-right: 1.5px solid var(--border-color);
  flex-flow: column;
  justify-content: space-between;
  min-width: 250px;
  max-width: 300px;
  padding-bottom: 20px;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
}

.nova_holder-software-grid-test {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  grid-template-rows: auto 1fr;
  grid-template-columns: minmax(auto, auto) auto 1fr;
  grid-auto-columns: 1fr;
  justify-content: space-between;
  width: 100%;
  min-height: 100vh;
  display: grid;
}

.menu_closed_logo_container {
  flex: 1;
  justify-content: center;
  display: flex;
  position: relative;
}

.image-36 {
  width: 32px;
  height: 32px;
}

.test_width {
  flex: 0 auto;
  justify-content: flex-start;
  align-items: stretch;
  width: 82vw;
  display: flex;
}

.nova_cards-view-pagination-contaner {
  border: 1px solid var(--border-color);
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
}

.nova_lead_action_image {
  width: 20px;
  height: 20px;
}

.nova_hovered_filter_container {
  z-index: 199;
  padding-top: 4px;
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
}

.nova_hovered_filter_container.visible {
  z-index: 99;
  display: flex;
}

.nova_hovered_filter_container.status_update {
  padding-top: 17px;
}

.nova_hovered_filter_container.status_update.visible {
  right: -10px;
}

.nova_hovered_filter_container.position_right {
  left: 0;
  right: auto;
}

.leads_sticky_view_stable_part {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  flex-flow: column;
  flex: 1;
  justify-content: flex-start;
  display: flex;
}

.try_make_bigger {
  height: 3000px;
}

.nova_user_stickyness {
  position: sticky;
  bottom: 20px;
}

.nova_brand_positioner {
  flex: 0 auto;
  justify-content: space-between;
  align-items: center;
  padding-top: 9px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
}

.nova_brand_positioner.small {
  padding-left: 0;
  padding-right: 0;
  position: relative;
}

.nova_menu_content {
  z-index: 9;
  position: absolute;
  top: 100%;
  left: 16px;
  right: 16px;
}

.nova_holder-notifications {
  width: 35%;
  min-width: 508px;
  height: 100vh;
  padding: 8px;
  position: absolute;
  inset: 0% 0% 0% auto;
}

.nova_notification {
  border: 1px solid var(--border-color);
  background-color: #fff;
  border-radius: 12px;
  flex-flow: column;
  height: 100%;
  padding-top: 20px;
  display: flex;
  position: relative;
}

.nova_up-part-notification {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
}

.nova_text-notification {
  color: var(--dark-text-color);
  text-transform: capitalize;
  font-family: Archivo, sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
}

.nova_holder-linkblocks-notifications {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.nova_bell-notifications {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  border: 1px solid var(--border-color);
  cursor: pointer;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  padding: 8px;
  text-decoration: none;
  display: flex;
}

.nova_text-notification-bell {
  color: var(--dark-text-color);
  font-family: Archivo, sans-serif;
  font-weight: 500;
  line-height: 18px;
}

.nova_holder-close-button {
  cursor: pointer;
  padding: 8px;
}

.nova_holder-notification-events {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  border-bottom: 1px solid var(--border-color);
  justify-content: flex-start;
  align-items: center;
  padding-top: 10px;
  padding-left: 20px;
  display: flex;
}

.nova_notification-button {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  cursor: pointer;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 22px;
  text-decoration: none;
  display: flex;
}

.nova_notification-button.selected {
  border-bottom: 2px solid var(--blue-nova);
  padding-bottom: 20px;
}

.nova_text-event {
  color: var(--text-color);
  font-family: Archivo, sans-serif;
  font-size: 16px;
  font-weight: 500;
}

.nova_event-number-box {
  background-color: var(--hover-selection);
  border-radius: 99px;
  padding: 4px 8px;
}

.nova_number-events {
  color: var(--blue-nova);
  font-family: Archivo, sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
}

.nova_holder-notifications-box {
  flex-flow: column;
  padding: 0;
  display: flex;
  overflow: auto;
}

.nova_notification-box {
  border-top: .5px solid var(--border-color);
  border-bottom: .5px solid var(--border-color);
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px 20px 20px 24px;
  display: flex;
}

.nova_notification-box.active {
  background-color: var(--hover-selection);
  border-radius: 0;
}

.nova_holder-date {
  aspect-ratio: 1 / 1.22;
  border: 1px solid #f57554;
  border-radius: 5px;
  flex-flow: column;
  width: 48px;
  min-width: 48px;
  height: 58px;
  display: flex;
  position: relative;
}

.nova_holder-month {
  background-color: #f57554;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 20px;
  display: flex;
}

.nova_month-in-box {
  color: #fff;
  font-family: Archivo, sans-serif;
  font-size: 8.74px;
  font-weight: 700;
  line-height: 11.24px;
}

.nova_holder-day-in-month {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.nova_day-in-month {
  color: var(--dark-text-color);
  font-family: Archivo, sans-serif;
  font-size: 22px;
  font-weight: 500;
  line-height: 28px;
}

.nova_holder-content-notification {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.nova_title-notification {
  color: var(--dark-text-color);
  cursor: pointer;
  font-family: Archivo, sans-serif;
  font-size: 16px;
  font-weight: 500;
}

.nova_notification-specs {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.nova_date-text {
  color: #57595b;
  font-family: Archivo, sans-serif;
  font-weight: 500;
  line-height: 18px;
}

.nova_time-text-notification {
  color: var(--dark-text-info);
  padding-left: 4px;
  font-family: Archivo, sans-serif;
  font-weight: 500;
  line-height: 18px;
}

.nova_description-notification {
  color: var(--dark-text-info);
  font-family: Archivo, sans-serif;
  font-weight: 500;
  line-height: 18px;
}

.nova_repporte-notification {
  aspect-ratio: 1;
  border: 1.5px solid var(--border-color);
  cursor: pointer;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  transition: background-color .2s;
  display: flex;
  position: relative;
}

.nova_repporte-notification:hover {
  background-color: var(--blue-nova);
}

.nova_repporte-notification.nan:hover {
  background-color: #0000;
}

.nova_shrink-close {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  justify-content: center;
  align-items: center;
  max-width: 120px;
  padding: 20px;
  display: flex;
}

.nova_button-model {
  aspect-ratio: 1;
  border: 1.5px solid var(--border-color);
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  display: flex;
}

.nova_chatbot-conversation {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.nova_add-user {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  border: 1px solid var(--border-color);
  cursor: pointer;
  border-radius: 99px;
  justify-content: center;
  align-items: center;
  padding: 8px 16px 8px 12px;
  text-decoration: none;
  display: flex;
}

.text-block-30 {
  color: var(--dark-text-color);
  font-family: Archivo, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

.nova_holder-input-conversation {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  border: 2px solid var(--border-color);
  background-color: #fff;
  border-radius: 12px;
  flex-flow: column;
  width: 60%;
  min-width: 550px;
  max-width: 625px;
  padding: 16px;
  display: flex;
}

.nova_input-chatbot {
  color: var(--dark-text-color);
  border: 1px solid #0000;
  min-height: 40px;
  margin-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  font-size: 16px;
}

.nova_holder-form-input-chatbot {
  color: var(--dark-text-color);
  margin-bottom: 0;
  font-family: Archivo, sans-serif;
  font-size: 16px;
  font-weight: 500;
}

.nova_holder-attachement-send {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.nova_attachement {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  cursor: pointer;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.text-block-31 {
  color: var(--text-color);
  font-family: Archivo, sans-serif;
  font-size: 16px;
  font-weight: 500;
}

.nova_button-send {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  background-color: var(--blue-nova);
  cursor: pointer;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  text-decoration: none;
  display: flex;
}

.text-block-32 {
  color: #fff;
  font-family: Archivo, sans-serif;
  font-size: 16px;
  font-weight: 500;
}

.nova_msg-chatbot-client {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  flex-flow: column;
  max-width: 625px;
  display: flex;
}

.nova_chatbot-msg {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  display: flex;
}

.nova_chatbot-img {
  padding-top: 8px;
}

.nova_chatbox {
  border: 1px solid var(--border-color);
  background-color: #fff;
  border-radius: 8px;
  justify-content: flex-start;
  align-items: center;
  max-width: 593px;
  padding: 12px;
  display: flex;
}

.nova_chatbox.like {
  padding-bottom: 56px;
  position: relative;
}

.nova_chatbox._2 {
  max-width: 271px;
}

.nova_text-chatbot {
  color: var(--dark-text-color);
  justify-content: flex-start;
  align-items: center;
  font-family: Archivo, sans-serif;
  font-size: 16px;
  font-weight: 500;
  display: flex;
}

.nova_text-chatbot._2 {
  font-size: 12px;
  line-height: 18px;
}

.nova_holder-to-right {
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.nova_chatbox-client {
  background-color: var(--hover-selection);
  border: 1px solid #dfdeff;
  border-radius: 8px;
  padding: 12px;
}

.nova_holder-answer-bot {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  flex-flow: column;
  display: flex;
}

.nova_holder-thumbs {
  grid-column-gap: 4px;
  grid-row-gap: 4px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  inset: auto 12px 12px auto;
}

.nova_thumbs {
  border: 1px solid var(--border-color);
  cursor: pointer;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  display: flex;
}

._180 {
  transform: rotate(180deg);
}

.nova_chatbot-profil {
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 350px;
  display: flex;
}

.nova_title-profile {
  color: var(--dark-text-color);
  text-align: center;
  margin-top: -30px;
  margin-bottom: 20px;
  font-family: Archivo, sans-serif;
  font-size: 36px;
  font-weight: 500;
  line-height: 46px;
}

.nova_profile-text {
  color: var(--text-color);
  text-align: center;
  font-family: Archivo, sans-serif;
  font-size: 16px;
  font-weight: 500;
}

.nova_shrinked-chatbot {
  border: 1.5px solid var(--border-color);
  background-color: #fff;
  flex-flow: column;
  width: 343px;
  height: 100vh;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  position: relative;
}

.nova_name-in-chat {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.nova_logo-24px {
  width: 24px;
}

.nova_name-profil {
  color: var(--dark-text-color);
  text-transform: uppercase;
  font-family: Archivo, sans-serif;
  font-size: 16px;
  font-weight: 700;
}

.nova_up-part-chatbot {
  border-bottom: 1px solid var(--border-color);
  justify-content: space-between;
  align-items: center;
  margin-right: 20px;
  padding-bottom: 16px;
  padding-left: 20px;
  display: flex;
}

.nova_holder-buttons-chatbot {
  display: flex;
}

.nova_button-menu-chatbot {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  display: flex;
}

.nova_close-button-small-chatbot {
  width: 24px;
}

.nova_holder-conversation-small-chatbot {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  flex-flow: column;
  display: flex;
}

.nova_small-chatbot-conversation {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  display: flex;
}

.image-37 {
  width: 24px;
}

.nova_holder-scroll-conversation {
  padding-top: 24px;
  padding-left: 20px;
  padding-right: 20px;
  overflow: scroll;
}

.nova_holder-input-text-msgs {
  background-color: #fff;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  position: absolute;
  inset: auto 0% 0%;
}

.nova_form-input-text-msgs {
  background-color: #fff;
}

.form-block-3 {
  margin-bottom: 0;
}

.nova_input-coversation {
  border: 1.5px solid var(--border-color);
  color: var(--text-color);
  border-radius: 12px;
  height: 54px;
  margin-bottom: 0;
  padding-left: 44px;
  padding-right: 32px;
  font-family: Archivo, sans-serif;
  font-weight: 500;
  line-height: 18px;
}

.nova_form-holder-input-msgs {
  position: relative;
}

.nova_holder-attachement-button {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  padding-left: 16px;
  padding-right: 12px;
  display: flex;
  position: absolute;
  inset: 0% auto 0% 0%;
}

.nova_button-send-small-conversation {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  padding-left: 12px;
  padding-right: 16px;
  display: flex;
  position: absolute;
  inset: 0% 0% 0% auto;
}

.nova_holder-last-update-dropbox {
  border: 1px solid var(--border-color);
  background-color: #fff;
  border-radius: 8px;
  min-width: 220px;
  max-height: 250px;
  padding: 8px 4px;
}

.nova_holder-links-dropbox {
  flex-flow: column;
  display: flex;
}

.nova_dropbox-links-lastupdate {
  cursor: pointer;
  border-radius: 8px;
  justify-content: flex-start;
  align-items: center;
  padding: 6px 6px 6px 8px;
  text-decoration: none;
  display: flex;
}

.nova_dropbox-links-lastupdate:hover {
  background-color: var(--hover-selection);
}

.nova_dropbox-links-lastupdate.small {
  padding-top: 1px;
  padding-bottom: 1px;
}

.nova_text-linkbox {
  color: var(--dark-text-color);
  font-family: Archivo, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.nova_filter_container {
  position: relative;
}

.nova_filter_input {
  border: 1px solid var(--border-color);
  color: var(--dark-text-color);
  border-radius: 4px;
  min-height: 40px;
  margin-bottom: 0;
  padding: 12px 16px;
  font-family: Archivo, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.nova_filters_horizontal_spliter {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
}

.try_child {
  background-color: var(--blue-nova);
  flex: 1;
  min-width: 250px;
  min-height: 40px;
}

.fake_max_width {
  max-width: 400px;
}

.fixed_shadow {
  z-index: 999;
  background-color: #0009;
  width: 100vw;
  height: 100vh;
  position: fixed;
}

.nova_checkbox-selection {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  border: 1px solid var(--border-color);
  background-color: #fff;
  border-radius: 8px;
  flex-flow: column;
  min-width: 230px;
  padding: 16px;
  display: flex;
}

.nova_center-checkbox {
  grid-column-gap: 60px;
  grid-row-gap: 60px;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.nova_form-checkbox {
  margin-bottom: 0;
}

.checkbox-label-2 {
  display: none;
}

.nova_holder-icon-text {
  grid-column-gap: 4px;
  grid-row-gap: 4px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.nova_holder-icon-text._8 {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
}

.text-block-33 {
  color: var(--dark-text-color);
  font-family: Archivo, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.nova_avatar-holder {
  grid-column-gap: 34px;
  grid-row-gap: 34px;
  flex-flow: column;
  max-width: 482px;
  max-height: 288px;
  padding: 20px;
  display: flex;
}

.nova_horizontal-avatar {
  grid-column-gap: 14px;
  grid-row-gap: 14px;
  display: flex;
}

.nova_blue-dot-notification {
  aspect-ratio: 1;
  background-color: var(--blue-nova);
  border: 2px solid #fff;
  border-radius: 50px;
  width: 12px;
  height: 12px;
  position: absolute;
  inset: -5px auto auto -4px;
}

.nova_add_filter_button_container {
  position: relative;
}

.nova_hovered_filter_list {
  display: none;
  position: absolute;
  inset: -3px auto auto -3px;
}

.nova_hovered_filter_list.visible {
  display: flex;
}

.try_vertical_space {
  min-height: 200px;
}

.try_max_width {
  max-width: 250px;
}

.nova_alert {
  background-color: #fdedee;
  border: 1px solid #e9d9da;
  border-radius: 8px;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 550px;
  padding: 20px;
  display: flex;
  box-shadow: 0 14px 34px #ed4d5f1a;
}

.nova_alert.warning {
  background-color: #fff9e9;
  border-color: #ebe5d5;
  box-shadow: 0 14px 34px #ffc32e1a;
}

.nova_alert.success {
  background-color: #ebfbf6;
  border-color: #d7e7e2;
  box-shadow: 0 14px 34px #30d49c1a;
}

.nova_alert.info {
  border-color: var(--border-color);
  background-color: #f6f8fa;
}

.nova_icon-text-alert {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.nova_title-alert {
  color: var(--dark-text-color);
  font-family: Archivo, sans-serif;
  font-size: 16px;
  font-weight: 700;
}

.nova_description-alert {
  color: var(--text-color);
  font-family: Archivo, sans-serif;
  font-weight: 500;
  line-height: 18px;
}

.nova_flex-v-text {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 434px;
  display: flex;
}

.nova_transfert-lead-popup {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  background-color: #fff;
  border-radius: 12px;
  flex-flow: column;
  max-width: 434px;
  padding-bottom: 20px;
  display: flex;
  position: relative;
}

.nova_uppart-popup {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  border-bottom: 1px solid var(--border-color);
  flex-flow: column;
  padding: 60px 20px 20px;
  display: flex;
}

.button-2 {
  background-color: var(--blue-nova);
  cursor: pointer;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
}

.text-block-34 {
  color: var(--dark-text-color);
  font-family: Archivo, sans-serif;
  font-size: 16px;
}

.dropdown-2 {
  width: 100%;
  height: 40px;
}

.dropdown-toggle-4 {
  border: 1px solid var(--border-color);
  background-color: #fff;
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  padding: 0 32px 0 16px;
  display: flex;
}

.text-block-35 {
  color: var(--dark-text-color);
  font-family: Archivo, sans-serif;
  font-weight: 500;
  line-height: 18px;
}

.icon-6 {
  margin-right: 12px;
}

.nova_popup-close-button {
  border: 1px solid var(--border-color);
  cursor: pointer;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  display: flex;
  position: absolute;
  inset: 16px 16px auto auto;
}

.nova_popup_confirmation {
  background-color: #fff;
  border-radius: 12px;
  flex-flow: column;
  max-width: 450px;
  display: flex;
}

.nova_holder-msg-popup {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding-top: 60px;
  padding-bottom: 20px;
  display: flex;
  position: relative;
}

.nova_holder-msg-popup.no_button {
  padding-bottom: 60px;
}

.nova_holder-text-popup {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-left: 40px;
  padding-right: 40px;
  display: flex;
}

.text-block-36 {
  color: var(--dark-text-color);
  text-align: center;
  font-family: Archivo, sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
}

.text-block-37 {
  color: var(--text-color);
  text-align: center;
  min-width: 350px;
  font-family: Archivo, sans-serif;
  font-size: 16px;
  font-weight: 500;
}

.nova_holder-buttons-popup {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  border-top: 1px solid var(--border-color);
  flex-flow: column;
  width: 100%;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
}

.nova_button-delete {
  color: #fff;
  background-color: #ed4d5f;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  padding-top: 12px;
  padding-bottom: 12px;
  font-family: Archivo, sans-serif;
  font-size: 16px;
  font-weight: 500;
  display: flex;
}

.nova_cancel-button {
  border: 1px solid var(--border-color);
  color: var(--dark-text-color);
  background-color: #fff;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  height: 56px;
  font-family: Archivo, sans-serif;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  display: flex;
}

.nova_input-name {
  color: var(--dark-text-color);
  border-radius: 8px;
  margin-bottom: 0;
  font-family: Archivo, sans-serif;
  font-weight: 500;
}

.nova_holder-input-name {
  margin-bottom: 0;
}

.nova_holder-form {
  border: 1px solid var(--border-color);
  border-radius: 8px;
}

.nova_onboarding-section {
  justify-content: space-between;
  align-items: stretch;
  height: 100vh;
  display: flex;
}

.nova_leftside-onboarding {
  flex: 1;
  justify-content: center;
  align-items: center;
  width: auto;
  display: flex;
}

.nova_onboarding-login {
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  flex-flow: column;
  min-width: 440px;
  display: flex;
}

.nova_vertical-text {
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.nova_heading {
  color: #000;
  font-family: Archivo, sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 38px;
}

.nova_heading.m40 {
  margin-bottom: 45px;
}

.text-block-38 {
  color: var(--text-color);
  font-family: Archivo, sans-serif;
  font-weight: 500;
  line-height: 18px;
}

.nova_bouton-google {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  border: 1px solid var(--border-color);
  background-color: #fff;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  padding: 16px;
  text-decoration: none;
  display: flex;
}

.text-block-39 {
  color: #22252f;
  font-family: Archivo, sans-serif;
  font-size: 16px;
  font-weight: 500;
}

.nova_holder-form-nova {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  flex-flow: column;
  display: flex;
}

.field-label {
  color: var(--dark-text-color);
  margin-bottom: 10px;
  font-family: Archivo, sans-serif;
  font-size: 16px;
  font-weight: 500;
}

.field-label.small {
  margin-bottom: 8px;
  font-size: 15px;
}

.nova_form-login {
  color: var(--dark-text-color);
  border: 1px solid #0000;
  border-radius: 8px;
  height: 52px;
  margin-bottom: 0;
  padding-left: 40px;
  font-family: Archivo, sans-serif;
  font-size: 16px;
  font-weight: 500;
}

.nova_holder-input-login {
  border: 1px solid var(--border-color);
  border-radius: 8px;
  margin-bottom: 20px;
  position: relative;
}

.nova_holder-input-login._2 {
  margin-bottom: 16px;
  position: static;
}

.nova_holder-icon-input-absolut {
  justify-content: center;
  align-items: center;
  padding-left: 16px;
  display: flex;
  position: absolute;
  inset: 0% auto 0% 0%;
}

.nova_remenberme-forgot {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  display: flex;
}

.checkbox-label-3 {
  color: var(--dark-text-color);
  font-family: Archivo, sans-serif;
  font-weight: 500;
  line-height: 18px;
}

.nova_forgot-password {
  color: #667aff;
  font-family: Archivo, sans-serif;
  font-weight: 500;
  line-height: 18px;
  text-decoration: none;
}

.nova_rememberme-checkbox {
  margin-bottom: 0;
}

.nova_login-button {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  background-color: var(--blue-nova);
  color: #fff;
  border: 1px solid #1837ff;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 56px;
  font-family: Archivo, sans-serif;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  display: flex;
}

.nova_login-button.small {
  height: 46px;
}

.nova_holder-horizontal-text {
  grid-column-gap: 4px;
  grid-row-gap: 4px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.text-block-40 {
  color: var(--text-color);
  font-family: Archivo, sans-serif;
  font-weight: 500;
  line-height: 18px;
}

.nova_signup-link {
  color: #667aff;
  font-family: Archivo, sans-serif;
  font-weight: 500;
  line-height: 18px;
}

.nova_logo-brand {
  position: absolute;
  inset: 20px auto auto 20px;
}

.text-block-41 {
  color: var(--text-color);
  font-family: Archivo, sans-serif;
  font-size: 16px;
  font-weight: 500;
  position: absolute;
  inset: auto auto 20px 20px;
}

.nova_rightside-onboarding {
  object-fit: cover;
  background-color: #f6f8fa;
  justify-content: flex-end;
  align-items: flex-end;
  width: 50%;
  padding-left: 90px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.nova_rightside-onboarding.last {
  justify-content: center;
  align-items: flex-end;
  padding-left: 0;
}

.nova_right-screen-img {
  object-fit: cover;
  flex: 1;
}

.nova_right-screen-img.normal {
  object-position: 0% 0%;
}

.nova_second-screen-onboarding {
  flex-flow: column;
  justify-content: space-between;
  align-items: stretch;
  min-width: 440px;
  max-width: 440px;
  height: 75%;
  min-height: 630px;
  max-height: 728px;
  display: flex;
}

.nova_button-back {
  grid-column-gap: 4px;
  grid-row-gap: 4px;
  cursor: pointer;
  justify-content: flex-start;
  align-items: center;
  height: 36px;
  padding-right: 4px;
  text-decoration: none;
  display: flex;
}

.text-block-42 {
  color: var(--dark-text-color);
  font-family: Archivo, sans-serif;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
}

.nova_celebration-login {
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.nova_holder-vertacal-text {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.text-block-43 {
  color: var(--text-color);
  font-family: Archivo, sans-serif;
  font-size: 16px;
  font-weight: 500;
}

.nova_uppart-second-screen {
  grid-column-gap: 33px;
  grid-row-gap: 33px;
  flex-flow: column;
  display: flex;
}

.nova_holder-login-steps {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.nova_line-steps {
  grid-column-gap: 3px;
  grid-row-gap: 3px;
  background-color: #f6f8fa;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  display: flex;
}

.nova_steps {
  background-color: #f6f8fa;
  border-radius: 20px;
  flex: 1;
  height: 3px;
}

.nova_steps._3 {
  width: 75%;
}

.nova_steps._4 {
  width: 100%;
}

.nova_steps.active {
  background-color: var(--blue-nova);
}

.text-block-44 {
  color: var(--dark-text-color);
  text-transform: capitalize;
  font-family: Archivo, sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
}

.text-span-10 {
  color: var(--dark-text-info);
}

.text-field-2 {
  color: var(--dark-text-info);
  border: 1px solid #0000;
  border-radius: 8px;
  height: 52px;
  margin-bottom: 0;
  padding-left: 16px;
  font-family: Archivo, sans-serif;
  font-size: 16px;
  font-weight: 500;
}

.text-field-2.small {
  height: 40px;
  font-size: 15px;
}

.nova_holder-form-button {
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  flex-flow: column;
  display: flex;
}

.nova_holder-title {
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 40px;
}

.nova_insurance-onboarding {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  flex-flow: column;
  display: flex;
}

.text-block-45 {
  color: var(--dark-text-info);
  font-family: Archivo, sans-serif;
  font-size: 16px;
  font-weight: 500;
}

.holder-buttons-login {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  flex-flow: column;
  display: flex;
}

.toast_positionner {
  z-index: 9999;
  position: fixed;
  top: 20px;
  right: 13px;
}

.nova_toast_close_buttn {
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.nova_toast_icon {
  width: 24px;
  height: 24px;
}

.nova_eclipse1 {
  position: absolute;
  inset: auto 0% 0% auto;
}

.nova_eclipse2 {
  position: absolute;
  inset: auto auto 0% 0%;
}

.nova_holder-text-img-login {
  border-left: 4px solid var(--blue-nova);
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
  height: 71px;
  padding-left: 10px;
  display: flex;
}

.nova_text-img {
  color: var(--dark-text-color);
  font-family: Archivo, sans-serif;
  font-size: 36px;
  font-weight: 500;
  line-height: 46px;
}

.nova_holder-text-img-onboard {
  grid-column-gap: 80px;
  grid-row-gap: 80px;
  flex-flow: column;
  display: flex;
  position: relative;
}

.nova_img-login {
  max-height: 70vh;
}

.popup_positionner {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
}

.popup_title {
  color: var(--dark-text-color);
  padding-right: 80px;
  font-family: Archivo, sans-serif;
  font-size: 16px;
}

.nova_attribute_dropdown {
  z-index: 99;
  flex: 1;
  position: relative;
}

.nova_rightside-onboarding-v2 {
  flex: 1;
  height: 100vh;
  display: flex;
  overflow: hidden;
}

.nova_holder-status_dropdown {
  background-color: #fff;
  margin-bottom: 0;
  position: relative;
}

.nova_onedim_stats {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-content: space-between;
  align-items: stretch;
  display: flex;
}

.nova_complex_leads {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  flex-flow: column;
  flex: 1;
  display: flex;
}

.nova_complex_leads.graph {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  flex: auto;
}

.nova_complex_leads.graph.large {
  flex: 1.7 auto;
}

.nova_complex_stats_top {
  border-bottom: 1px solid var(--border-color);
  padding: 20px;
  display: flex;
}

.nova_complex_stats_bottom {
  padding: 15px 20px 20px;
}

.nova_complex_stats_bottom.small_bottom_margin {
  padding-bottom: 5px;
}

.nova_complex_stats_bottom.more_side_paddings {
  padding-left: 45px;
  padding-right: 45px;
}

.nova_complex_stats_bottom.more_side_paddings.less_top_padding {
  padding-top: 0;
}

.try_graph {
  background-color: var(--blue-status);
  width: 100%;
  height: 140px;
}

.nova_card {
  border: 1px solid var(--border-color);
  border-radius: 8px;
}

.noa_dashboard_leads_multibuttons {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  justify-content: center;
  align-items: stretch;
  display: flex;
}

.noa_dashboard_leads_multibuttons.small_gap {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
}

.nova_dashboards_lead_top, .nova_dashboards_lead_second_top {
  border-bottom: 1px solid var(--border-color);
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  display: flex;
}

.nova_leads_filter_button {
  border: 1px solid var(--border-color);
  cursor: pointer;
  background-color: #fff;
  border-radius: 8px;
  flex-flow: row;
  justify-content: space-around;
  align-items: center;
  padding: 11px 16px;
  display: flex;
}

.nova_leads_filter_button:hover {
  background-color: var(--hover-selection);
}

.nova_leads_filter_button.selected {
  border-color: var(--hover-selection);
  background-color: var(--hover-selection);
}

.div-block {
  background-color: var(--border-color);
  width: 1px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.nova_action_img {
  cursor: pointer;
  width: 16px;
  height: 16px;
}

.nova_notification_card_left {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  flex: 1;
  align-items: flex-start;
  display: flex;
}

.nova_priorities_hover {
  color: var(--dark-text-color);
  cursor: pointer;
  border-radius: 99px;
  padding: 4px 12px;
  font-family: Archivo, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-decoration: none;
  transition: background-color .2s;
}

.nova_priorities_hover:hover {
  background-color: #f4f3ff;
}

.nova_priorities_hover.active {
  background-color: var(--hover-selection);
  color: var(--blue-nova);
}

.nova_priorities_hover.active.medium {
  color: #543d18;
  background-color: #ffeab0;
}

.nova_priorities_hover.active.high {
  color: #711414;
  background-color: #fdeaea;
}

.nova_lists_select_left {
  display: flex;
}

.nova_lists_select_right {
  border-bottom: 1.5px solid var(--border-color);
  flex: 1;
  justify-content: flex-end;
  display: flex;
}

.nova_arrow_container {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  cursor: pointer;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  display: flex;
}

.nova_lists_space {
  border-bottom: 1.5px solid var(--border-color);
  width: 19px;
  height: 100%;
}

.nova_add_event_popup {
  z-index: 999;
  background-color: #fff;
  border-radius: 12px;
  flex-flow: column;
  justify-content: space-between;
  align-items: stretch;
  min-width: 40vw;
  min-height: 60vh;
  display: flex;
}

.nova_popup-close-button-standalone {
  border: 1px solid var(--border-color);
  cursor: pointer;
  border-radius: 8px;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  display: flex;
}

.popup_add_event_topp {
  border-bottom: 1px solid var(--border-color);
  border-radius: 1px;
  justify-content: space-between;
  align-items: stretch;
  padding: 12px;
  display: flex;
}

.nova_popup_title {
  color: var(--dark-text-color);
  white-space: nowrap;
  font-family: Archivo, sans-serif;
  font-size: 16px;
  font-weight: 700;
}

.popup_add_event_top_left {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.popup_add_event_bottom {
  border-top: 1px solid var(--border-color);
  flex-flow: row-reverse;
  justify-content: space-between;
  align-items: stretch;
  padding: 12px;
  display: flex;
}

.nova_popup_ad_rminder_middle {
  flex: 1;
  display: flex;
}

.mova_add_reminder_left {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  border-right: 1px solid var(--border-color);
  flex-flow: column;
  width: 45%;
  max-width: 229px;
  padding: 12px 20px;
  display: flex;
}

.nova_add_reminder_right {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  flex-flow: column;
  flex: 1;
  padding: 12px 20px;
  display: flex;
}

.nova_grey_subtitle {
  color: var(--text-color);
  font-family: Archivo, sans-serif;
  font-weight: 700;
  line-height: 18px;
}

.nova_button {
  background-color: var(--blue-nova);
  cursor: pointer;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  font-family: Archivo, sans-serif;
  display: flex;
}

.nova_button.unclickable {
  background-color: var(--text-color);
  color: var(--dark-text-color);
  cursor: auto;
}

.nova_button.bold {
  font-weight: 700;
}

.nova_button.bold.unclickable {
  background-color: var(--border-color);
  color: var(--dark-text-info);
  cursor: auto;
}

.nova_holder-client {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.nova_text-nae-client {
  color: var(--dark-text-color);
  font-family: Archivo, sans-serif;
  font-weight: 500;
  line-height: 18px;
}

.nova_icon-text-part-calendar {
  grid-column-gap: 4px;
  grid-row-gap: 4px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.nova_text-icon-bold {
  color: var(--dark-text-color);
  font-family: Archivo, sans-serif;
  font-weight: 700;
  line-height: 18px;
}

.nova_icon-16 {
  width: 16px;
}

.nova_small-calendar {
  background-color: var(--blue-status);
  width: 100%;
  min-width: 400px;
  height: 450px;
}

.nova_content-dropdown {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  flex-flow: column;
  display: flex;
}

.nova_dropdown-input-timing {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.nova_holder-input-dropdown-time {
  border: 1px solid var(--border-color);
  border-radius: 8px;
  justify-content: flex-start;
  align-items: center;
  width: 50%;
  height: 42px;
  display: flex;
  position: relative;
}

.form-block-4 {
  margin-bottom: 0;
}

.nova_input-time {
  color: var(--text-color);
  background-color: #0000;
  border: 1px solid #0000;
  border-radius: 8px;
  min-height: 42px;
  margin-bottom: 0;
  font-family: Archivo, sans-serif;
  font-weight: 500;
  line-height: 18px;
}

.nova_absolut-arrow-dropdown {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  position: absolute;
  inset: 0% 0% 0% auto;
}

.nova_dropdown-timing {
  border: 1px solid var(--border-color);
  background-color: #fff;
  border-radius: 8px;
  max-height: 140px;
  position: absolute;
  inset: 42px 0% auto;
  overflow: scroll;
}

.nova_holder-time-in-dropdown {
  border-bottom: 1px solid var(--border-color);
  justify-content: space-between;
  align-items: center;
  height: 35px;
  padding: 12px;
  display: flex;
}

.nova_text-time {
  color: var(--text-color);
  font-family: Archivo, sans-serif;
  font-weight: 500;
  line-height: 18px;
}

.nova_add_large_event_popup {
  z-index: 999;
  background-color: #fff;
  border-radius: 12px;
  flex-flow: column;
  justify-content: space-between;
  align-items: stretch;
  width: 90vw;
  min-width: 561px;
  max-width: 1154px;
  height: 60vw;
  min-height: 510px;
  max-height: 656px;
  display: flex;
}

.popup_add_large_event_topp {
  border-bottom: 1px solid var(--border-color);
  border-radius: 1px;
  justify-content: space-between;
  align-items: stretch;
  padding: 12px;
  display: flex;
}

.nova_popup_ad_large_rminder_middle {
  flex-flow: column;
  flex: 1;
  display: flex;
}

.popup_add_large_event_bottom {
  border-top: 1px solid var(--border-color);
  flex-flow: row-reverse;
  justify-content: space-between;
  align-items: stretch;
  padding: 12px;
  display: flex;
}

.mova_add_large_reminder_top {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  border-right: 1px solid var(--border-color);
  flex-flow: column;
  padding: 12px;
  display: flex;
}

.nova_add_large_reminder_second {
  border-top: 1px solid var(--border-color);
  flex-flow: row;
  flex: 1;
  justify-content: space-between;
  display: flex;
}

.nova_icon-text-large-part-calendar {
  grid-column-gap: 4px;
  grid-row-gap: 4px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.nova_holder-small-calender-in-large-section {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  flex-flow: column;
  width: 34%;
  padding: 20px;
  display: flex;
}

.nova_small-calender {
  background-color: var(--blue-status);
  max-width: 300px;
  height: 300px;
  max-height: 300px;
}

.nova_holder-type-calendars-large {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  flex-flow: column;
  width: 22%;
  padding: 20px 12px;
  display: flex;
}

.holder-up-part-calendar-large {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.nova_text-up-calendar-large {
  color: var(--dark-text-color);
  font-family: Archivo, sans-serif;
  font-weight: 700;
  line-height: 18px;
}

.nova_button-change-sales {
  border: 1px solid var(--border-color);
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  min-width: 100px;
  height: 40px;
  padding: 11px 12px;
  text-decoration: none;
  display: flex;
}

.text-block-46 {
  color: var(--dark-text-color);
  font-family: Archivo, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.nova_type-calendar {
  background-color: var(--blue-status);
  width: 100%;
  height: 100%;
}

.popup_add_confirme_event_top {
  border-bottom: 1px solid var(--border-color);
  border-radius: 1px;
  justify-content: space-between;
  align-items: stretch;
  padding: 12px;
  display: flex;
}

.popup_add_confirme_event_top_left {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.nova_holder-button-back-text {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.nova_button-back-page-calendar {
  border: 1px solid var(--border-color);
  background-color: #fff;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  display: flex;
}

.nova_icon_20 {
  width: 20px;
}

.nova_popup_ad_confirme_rminder_middle {
  flex-flow: row;
  flex: 1;
  max-height: 79%;
  display: flex;
}

.mova_add_confirme_reminder_left {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  border-right: 1px solid var(--border-color);
  flex-flow: column;
  min-width: 229px;
  padding: 12px;
  display: flex;
}

.nova_add_confirme_event_popup {
  z-index: 999;
  background-color: #fff;
  border-radius: 12px;
  flex-flow: column;
  justify-content: space-between;
  align-items: stretch;
  width: 60vw;
  min-width: 561px;
  max-width: 851px;
  height: 60vw;
  min-height: 510px;
  max-height: 750px;
  display: flex;
}

.text-block-47 {
  color: var(--text-color);
  font-family: Archivo, sans-serif;
  font-weight: 700;
  line-height: 18px;
}

.nova_design-line {
  background-color: var(--border-color);
  width: 100%;
  height: 1px;
}

.nova_holder-info-schedule {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.text-block-48 {
  color: var(--dark-text-info);
  font-family: Archivo, sans-serif;
  font-weight: 500;
  line-height: 18px;
}

.nova_holder-text-verticale {
  grid-column-gap: 2px;
  grid-row-gap: 2px;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.nova_add_confirme_reminder_right {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  border-top: 1px solid var(--border-color);
  flex-flow: column;
  flex: 1;
  justify-content: flex-start;
  padding: 12px;
  display: flex;
  overflow: scroll;
}

.text-block-49 {
  color: var(--dark-text-color);
  font-family: Archivo, sans-serif;
  font-size: 16px;
  font-weight: 700;
}

.nova_holder-info-confirme-schedule {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.text-block-50 {
  color: var(--dark-text-color);
  font-family: Archivo, sans-serif;
  font-weight: 500;
  line-height: 18px;
}

.nova_holder-input-form-confirme {
  border: 1px solid var(--border-color);
  background-color: #fff;
  border-radius: 8px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 52px;
  display: flex;
  position: relative;
}

.nova_input-info-confirme {
  color: var(--text-color);
  border: 1px solid #0000;
  border-radius: 8px;
  width: 100%;
  height: 50px;
  margin-bottom: 0;
  padding-left: 16px;
  font-family: Archivo, sans-serif;
  font-size: 16px;
  font-weight: 500;
}

.nova_input-info-confirme.black {
  color: var(--dark-text-color);
}

.form-block-5 {
  width: 100%;
  height: 100%;
  margin-bottom: 0;
}

.nova_holder-infos-confirme {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  flex-flow: column;
  display: flex;
}

.nova_button-show-dropdown {
  padding: 16px;
  position: absolute;
  inset: 0% 0% 0% auto;
}

.nova_dropdown-info-confirme {
  border: 1px solid var(--border-color);
  border-radius: 8px;
  max-height: 120px;
  display: none;
  position: absolute;
  inset: 50px 0% auto;
  overflow: scroll;
}

.nova_selection-dropdown-confirme {
  border-bottom: 1px solid var(--border-color);
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 40px;
  padding-left: 16px;
  display: flex;
}

.nova_text-dropdown-confirme {
  color: var(--text-color);
  font-family: Archivo, sans-serif;
  font-size: 16px;
  font-weight: 500;
}

.nova_status_blocs {
  grid-column-gap: 6px;
  grid-row-gap: 6px;
  justify-content: center;
  align-items: stretch;
  display: flex;
}

.nova_chatbot-section {
  background-color: #f6f8fa;
  justify-content: space-between;
  width: 100%;
  height: 100vh;
  display: flex;
}

.nova_menu-chatbot {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  border-right: 1.5px solid var(--border-color);
  background-color: #fff;
  flex-flow: column;
  padding: 20px;
  display: flex;
  overflow: hidden;
}

.nova_menu-chatbot.small {
  padding-left: 20px;
  padding-right: 20px;
}

.nova_brand-close-menu {
  border-bottom: 1.5px solid var(--border-color);
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
  display: flex;
}

.nova_brand-menu {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.nova_brand-menu.small {
  flex: 1;
  justify-content: center;
  align-items: center;
  padding-top: 4px;
  padding-bottom: 4px;
}

.nova_icon-24 {
  width: 24px;
}

.text-block-51 {
  color: var(--dark-text-color);
  font-family: Archivo, sans-serif;
  font-size: 16px;
  font-weight: 700;
}

.text-block-51.small {
  display: none;
}

.nova_close-menu-chatbot {
  border: 1.5px solid var(--border-color);
  cursor: pointer;
  background-color: #fff;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  display: flex;
}

.nova_holder-button-new-conversation {
  border-bottom: 1.5px solid var(--border-color);
  flex-flow: column;
  padding-top: 2px;
  padding-bottom: 24px;
  display: flex;
}

.nova_holder-button-new-conversation.small {
  border-bottom-width: 0;
}

.nova_button-create-new-conversation {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  border: 1.5px solid var(--border-color);
  color: var(--dark-text-color);
  cursor: pointer;
  background-color: #fff;
  border-radius: 8px;
  justify-content: flex-start;
  align-items: center;
  padding: 18px 16px;
  font-family: Archivo, sans-serif;
  font-weight: 500;
  line-height: 18px;
  text-decoration: none;
  display: flex;
}

.nova_button-create-new-conversation.small {
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  padding: 0;
  transition: background-color .3s;
}

.nova_button-create-new-conversation.small:hover {
  background-color: var(--hover-selection);
}

.nova_icon-18 {
  width: 18px;
}

.nova_holder-history-conversation {
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.nova_holder-history-conversation.small {
  display: none;
}

.nova_text-type-history {
  color: var(--text-color);
  margin-bottom: 10px;
  font-family: Archivo, sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
}

.nova_content-history {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding-top: 9px;
  padding-bottom: 9px;
  text-decoration: none;
  display: flex;
}

.nova_text-history {
  color: var(--dark-text-color);
  font-family: Archivo, sans-serif;
  font-weight: 500;
  line-height: 18px;
}

.nova_page-chatbot {
  background-color: #f6f8fa;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  max-height: 100vh;
  padding: 75px;
  display: flex;
  position: relative;
}

.nova_absolut-holder-buttons {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  inset: 20px 20px auto auto;
}

.nova_button-shrink-grow-chatbot {
  border: 1.5px solid var(--border-color);
  cursor: pointer;
  background-color: #fff;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  display: flex;
}

.nova_holder-content-chatbot {
  grid-column-gap: 15vh;
  grid-row-gap: 15vh;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.nova_holder-icon-star-chatbot {
  background-image: linear-gradient(135deg, var(--blue-nova), #ff7e7933 69%, #ff7e791a 102%);
  border: 5px solid #fff;
  border-radius: 99px;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 120px;
  display: flex;
  box-shadow: 0 14px 34px #ffccf766;
}

.nova_holder-up-part-chatbot {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  max-width: 350px;
  display: flex;
}

.niva_title-chatbot {
  color: var(--dark-text-color);
  text-align: center;
  font-family: Archivo, sans-serif;
  font-size: 34px;
  font-weight: 500;
  line-height: 46px;
}

.nova_subtitle-chatbot {
  color: var(--text-color);
  text-align: center;
  font-family: Archivo, sans-serif;
  font-size: 16px;
  font-weight: 500;
}

.nova_down-part-chatbot {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.nova_button-add-users {
  grid-column-gap: 4px;
  grid-row-gap: 4px;
  border: 1.5px solid var(--border-color);
  cursor: pointer;
  background-color: #fff;
  border-radius: 99px;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 16px 8px 12px;
  text-decoration: none;
  display: flex;
}

.text-block-52 {
  color: var(--dark-text-color);
  font-family: Archivo, sans-serif;
  font-size: 16px;
}

.nova_holder-input-conversation-chatbot {
  border-radius: 12px;
  flex-flow: column;
  width: 90%;
  min-width: 620px;
  max-width: 800px;
  display: flex;
  box-shadow: 0 14px 24px #b0b2b41a;
}

.nova_text-field-conversation-chatbot {
  border: 1.5px solid var(--border-color);
  color: var(--dark-text-color);
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  min-height: 66px;
  margin-bottom: 0;
  padding: 23px 16px;
  font-family: Archivo, sans-serif;
  font-size: 16px;
  font-weight: 500;
}

.form-block-6 {
  margin-bottom: 0;
}

.nova_relative-holder-form {
  position: relative;
}

.nova_absolut-holder-button-send {
  justify-content: center;
  align-items: center;
  padding: 16px;
  display: flex;
  position: absolute;
  inset: 0% 0% 0% auto;
}

.nova_button-send-chatbot {
  grid-column-gap: 4px;
  grid-row-gap: 4px;
  background-color: var(--blue-nova);
  border-radius: 8px;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 12px 8px 16px;
  text-decoration: none;
  transition: box-shadow .3s, background-color .3s;
  display: flex;
}

.nova_button-send-chatbot:hover {
  background-color: #0224ff;
  box-shadow: 0 0 3px 3px #0000001a;
}

.text-block-53 {
  color: #fff;
  font-family: Archivo, sans-serif;
  font-weight: 500;
  line-height: 18px;
}

.nova_div-holder-attachement {
  border-style: solid;
  border-width: 0 1px 1px;
  border-color: var(--border-color);
  background-color: #f6f8fa;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
  padding: 16px;
}

.nova_attachement-button {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.text-block-54 {
  color: var(--dark-text-info);
  font-family: Archivo, sans-serif;
  font-size: 16px;
  font-weight: 500;
}

.nova_holder-msg-conversation {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  flex-flow: column;
  width: 80%;
  min-width: 600px;
  max-width: 800px;
  min-height: 100%;
  padding-bottom: 16px;
  display: flex;
  overflow: auto;
}

.nova_chat-ai {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.nova_holder-img-ai {
  min-width: 24px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.nova_holder-msg {
  border: 1px solid var(--border-color);
  background-color: #fff;
  border-radius: 8px;
  padding: 12px;
  position: relative;
}

.nova_holder-msg.metrics {
  padding-bottom: 56px;
}

.nova_holder-msg.formulaire {
  flex-flow: column;
  display: flex;
}

.nova_parent-holder-msgs {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  flex-flow: column;
  display: flex;
}

.nova_parent-holder-msgs.formulaire {
  flex: 1;
}

.text-block-55 {
  color: var(--dark-text-color);
  font-family: Archivo, sans-serif;
  font-size: 16px;
  font-weight: 500;
}

.nova_user-msg {
  justify-content: flex-end;
  align-items: flex-start;
  display: flex;
}

.nova_msg-user {
  background-color: #f4f3ff;
  border: 1px solid #dfdeff;
  border-radius: 8px;
  padding: 12px;
}

.nova_holder-conversation-chatbot {
  background-color: #f6f8fa;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  max-height: 100vh;
  padding: 75px 75px 158px;
  display: flex;
  position: relative;
}

.nova_absolut-holder-input-conversation {
  justify-content: center;
  align-items: flex-start;
  padding-bottom: 40px;
  display: flex;
  position: absolute;
  inset: auto 0% 0%;
}

.nova_absolut-holder-likes-button {
  grid-column-gap: 4px;
  grid-row-gap: 4px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  inset: auto 12px 12px auto;
}

.nova_like-button {
  border: 1px solid var(--border-color);
  background-color: #fff;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  transition: background-color .3s;
  display: flex;
}

.nova_like-button:hover {
  background-color: var(--hover-selection);
}

.utility-page-wrap {
  justify-content: center;
  align-items: center;
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  max-height: 100%;
  display: flex;
}

.utility-page-content {
  text-align: center;
  flex-direction: column;
  width: 260px;
  display: flex;
}

.utility-page-form {
  flex-direction: column;
  align-items: stretch;
  display: flex;
}

.text-field-3 {
  border: 1px solid var(--border-color);
  color: var(--dark-text-color);
  border-radius: 8px;
  min-height: 52px;
  margin-bottom: 0;
  padding: 17px 48px 17px 16px;
  font-family: Archivo, sans-serif;
  font-size: 16px;
  font-weight: 500;
}

.field-label-2 {
  color: var(--dark-text-color);
  margin-bottom: 10px;
  font-family: Archivo, sans-serif;
  font-size: 16px;
  font-weight: 500;
}

.nova_holder-form-conversation-chatbot {
  width: 100%;
}

.nova_holder-form-inputs-chatbot {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  flex-flow: column;
  display: flex;
}

.nova_holder-2-form-chatbot {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.nova_holder-submit-button {
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.nova_holder-submit-button.right {
  justify-content: flex-end;
}

.nova_holder-submit-button.separated {
  justify-content: space-between;
}

.nova_button-add-user {
  background-color: var(--blue-nova);
  color: #fff;
  border: 1px solid #1837ff;
  border-radius: 8px;
  padding: 12px 24px;
  font-family: Archivo, sans-serif;
  font-weight: 500;
  line-height: 18px;
}

.form-block-7 {
  margin-bottom: 12px;
}

.nova_relative-box-dropbox {
  position: relative;
}

.nova_dropbox-formulaire {
  z-index: 10;
  border: 1px solid var(--border-color);
  background-color: #fff;
  border-radius: 8px;
  flex-flow: column;
  max-height: 160px;
  display: none;
  position: absolute;
  inset: 52px 0% auto;
  overflow: auto;
}

.nova_dropbox-formulaire.visible {
  display: flex;
}

.nova_drop-navlink {
  border-bottom: 1px solid var(--border-color);
  cursor: pointer;
  border-radius: 8px;
  justify-content: flex-start;
  align-items: center;
  min-height: 40px;
  padding-left: 16px;
  text-decoration: none;
  display: flex;
}

.nova_drop-navlink:hover {
  background-color: var(--hover-selection);
}

.nova_text {
  color: var(--text-color);
  font-family: Archivo, sans-serif;
  font-weight: 500;
}

.nova_dropbox-button {
  justify-content: center;
  align-items: center;
  padding: 16px;
  display: flex;
  position: absolute;
  inset: 0% 0% 0% auto;
}

.nova_chatform_button_container {
  display: flex;
}

.nova_chatform_button_container.right {
  flex-flow: row-reverse;
}

.nova_dropdown_text_container {
  flex-flow: column;
  justify-content: center;
  align-items: stretch;
  display: flex;
}

.nova_setting-disconnect {
  z-index: 2999;
  grid-column-gap: 4px;
  grid-row-gap: 4px;
  border: 1px solid var(--border-color);
  background-color: #fff;
  border-radius: 8px;
  flex-flow: column;
  padding: 4px;
  display: flex;
}

.nova_setting-disconnect.positioned {
  width: 100%;
  position: absolute;
  bottom: 84px;
}

.nova_setting-disconnect.positioned.closed {
  width: auto;
  min-width: 220px;
  bottom: 70px;
  left: -10px;
}

.nova_link-settings {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  color: var(--dark-text-color);
  cursor: pointer;
  background-color: #fff;
  border-radius: 8px;
  justify-content: flex-start;
  align-items: center;
  min-height: 42px;
  padding-left: 8px;
  font-family: Archivo, sans-serif;
  font-weight: 500;
  line-height: 18px;
  text-decoration: none;
  transition: background-color .3s;
  display: flex;
}

.nova_link-settings:hover {
  background-color: var(--hover-selection);
}

.nova_number-users {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  background-color: var(--dark-text-color);
  border-radius: 8px;
  flex-flow: column;
  max-width: 162px;
  padding: 12px;
  display: flex;
  position: relative;
}

.nova_lead_editors {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  flex-flow: column;
  display: flex;
}

.text-block-56 {
  color: #fffc;
  font-family: Archivo, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}

.nova_holder-icons-lead-editor {
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.nova_holder-down-arrow {
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  inset: auto 0% -7%;
}

.nova_input-name-lead {
  color: var(--dark-text-color);
  text-transform: capitalize;
  background-color: #0000;
  border: 1px solid #0000;
  height: 20px;
  margin-bottom: 0;
  padding: 0;
  font-family: Archivo, sans-serif;
  font-size: 16px;
  font-weight: 700;
}

.form-block-8 {
  margin-bottom: 0;
}

.try_container {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  flex: 1;
  display: flex;
  overflow: auto;
}

.try_block {
  background-color: #add8e6;
  min-width: 400px;
  height: 100px;
}

.try_menu_splitter {
  display: flex;
}

.try_menu_content {
  background-color: pink;
  width: 200px;
  height: 400px;
}

.try_large_width {
  background-color: pink;
  width: 1000px;
  height: 50px;
}

.ty_large_width_overflower {
  margin-top: 400px;
  overflow: auto;
}

.nova_back_button_container {
  display: flex;
}

.onboarding_back_button {
  height: 16px;
  padding: 3px 4px;
}

.try_aircall {
  background-color: green;
  width: 20px;
  height: 20px;
}

.div-block-2, .nova_user-card_container {
  position: relative;
}

.nova_table_more_leads {
  justify-content: center;
  padding: 10px;
  display: flex;
}

.nova_closed_menu_bottom_conainr {
  grid-column-gap: 14px;
  grid-row-gap: 14px;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.la_available_leads_count {
  color: var(--dark-text-info);
  font-family: Archivo, sans-serif;
  font-size: 16px;
  font-weight: 500;
}

.nova_status-type-2nd-border {
  border: 1px solid var(--white);
  cursor: pointer;
  border-radius: 4px;
}

.nova_status-type-2nd-border:hover {
  border-color: var(--blue-nova);
}

.nova_admin-collaborateur-div {
  grid-column-gap: 80px;
  grid-row-gap: 80px;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 40px 20px;
  display: flex;
}

.nova_horizontal-text {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  color: var(--dark-text-color);
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 300px;
  max-width: 328px;
  font-family: Archivo, sans-serif;
  font-size: 16px;
  font-weight: 500;
  display: flex;
}

.text-block-57 {
  color: var(--text-color);
  font-size: 12px;
  line-height: 18px;
}

.nova-holder-buttons-collaboration {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  flex-flow: column;
  flex: 1;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.nova_dropdown-collaborateur {
  border: 1px solid var(--border-color);
  color: var(--dark-text-color);
  border-radius: 8px;
  margin-bottom: 0;
  padding: 11px 12px 11px 16px;
  font-family: Archivo, sans-serif;
  font-weight: 500;
  line-height: 18px;
  box-shadow: 0 1px 1px #57595b1a;
}

.form-block-9 {
  margin-bottom: 0;
}

.nova_horizontal-boxes-collaborateur {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.nova_holder-button-text {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  flex-flow: column;
  display: flex;
}

.nova_holder-img-text-button {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  border: 1px solid var(--border-color);
  background-color: #f6f8fa;
  border-radius: 8px;
  justify-content: flex-start;
  align-items: center;
  padding: 11px 16px 11px 12px;
  text-decoration: none;
  display: flex;
}

.text-block-58 {
  color: var(--dark-text-color);
  font-family: Archivo, sans-serif;
  font-weight: 500;
  line-height: 18px;
}

.text-block-59 {
  color: var(--text-color);
  font-family: Archivo, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}

.nova_small-design-vertical {
  background-color: var(--border-color);
  width: 1px;
  height: 24px;
}

.nova_notification-bell {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  border: 1.5px solid var(--border-color);
  background-color: #fff;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  max-width: 103px;
  padding: 8px;
  text-decoration: none;
  display: flex;
}

.text-block-60 {
  color: #2e2e2e;
  font-family: Archivo, sans-serif;
  font-weight: 500;
  line-height: 18px;
}

.nova_icon-32 {
  width: 32px;
}

.form-2 {
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.nova_cancel-button-reminder {
  border: 1px solid var(--border-color);
  color: var(--dark-text-color);
  background-color: #fff;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  padding-top: 12px;
  padding-bottom: 12px;
  font-family: Archivo, sans-serif;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  display: flex;
}

.nova_take-appointement {
  border: 1px solid var(--border-color);
  border-radius: 8px;
  flex-flow: column;
  max-width: 458px;
  display: flex;
}

.nova_title-close {
  border-bottom: 1px solid var(--border-color);
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
}

.nova_middle-section {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  flex-flow: column;
  padding: 20px;
  display: flex;
}

.nova_search-result {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  flex-flow: column;
  display: flex;
}

.nova_search-barr {
  border: 1px solid var(--border-color);
  box-shadow: 0 1px 1px 0 var(--dark-text-10);
  color: var(--dark-text-color);
  border-radius: 8px;
  align-items: baseline;
  margin-bottom: 0;
  padding-left: 36px;
  font-family: Archivo, sans-serif;
  font-weight: 500;
  line-height: 18px;
  display: flex;
}

.form-block-10 {
  margin-bottom: 0;
}

.text-block-61 {
  color: var(--dark-text-color);
  font-family: Archivo, sans-serif;
  font-size: 16px;
  font-weight: 700;
}

.nova_holder-absolut-icon {
  justify-content: center;
  align-items: center;
  padding: 12px 8px 12px 12px;
  display: flex;
  position: absolute;
  inset: 0% auto 0% 0%;
}

.nova_holder-search-lead {
  flex-flow: column;
  display: flex;
}

.nova_lead-search {
  justify-content: space-between;
  align-items: center;
  height: 42px;
  display: flex;
}

.nova_holder-name-img {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.text-block-62 {
  color: var(--dark-text-color);
  font-family: Archivo, sans-serif;
  font-weight: 500;
  line-height: 18px;
}

.text-block-63 {
  color: var(--dark-text-info);
  font-family: Archivo, sans-serif;
  font-weight: 500;
  line-height: 18px;
}

.checkbox-2 {
  border: 1px solid var(--border-color);
  box-shadow: 0 1px 1px 0 var(--dark-text-10);
  width: 16px;
  height: 16px;
  margin-left: -28px;
}

.nova_text-checkbox {
  color: var(--dark-text-info);
  max-width: 280px;
  font-family: Archivo, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.checkbox-field-2 {
  padding-left: 28px;
}

.nova_holder-button-last-part {
  border-top: 1px solid var(--border-color);
  justify-content: flex-end;
  align-items: center;
  padding: 12px 20px 12px 12px;
  display: flex;
}

.nova_button-next {
  background-color: var(--border-color);
  color: var(--dark-text-info);
  text-transform: capitalize;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  font-family: Archivo, sans-serif;
  font-weight: 500;
  line-height: 18px;
  display: flex;
}

.nova_downside-notification {
  border-top: 1px solid var(--border-color);
  padding: 20px;
}

.nova_link-mark-all-as-read {
  color: var(--dark-text-color);
  font-family: Archivo, sans-serif;
  font-size: 16px;
  font-weight: 500;
}

.nova_holder-function-notifications {
  grid-column-gap: 4px;
  grid-row-gap: 4px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.nova_absolut-icon-on-hover.grey, .nova_absolut-icon-on-hover.white {
  display: none;
}

.nova_title-dropdown {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.nova_title-dd {
  color: var(--text-color);
  font-family: Archivo, sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
}

.nova_link-dropdown {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  border: 1px solid var(--border-color);
  background-color: #fff;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  padding: 11px 12px 11px 16px;
  text-decoration: none;
  display: flex;
}

.nova_dropdown-text {
  color: var(--dark-text-color);
  font-family: Archivo, sans-serif;
  font-weight: 500;
  line-height: 18px;
}

.nova_text-button-new-conversation.small {
  display: none;
}

@media screen and (min-width: 1280px) {
  .mova_add_reminder_left, .mova_add_large_reminder_top {
    padding: 20px;
  }

  .nova_popup_ad_confirme_rminder_middle {
    max-height: none;
  }

  .mova_add_confirme_reminder_left {
    padding: 20px;
  }

  .nova_add_confirme_reminder_right {
    grid-column-gap: 24px;
    grid-row-gap: 24px;
    padding: 20px;
  }

  .nova_holder-content-chatbot {
    grid-column-gap: 206px;
    grid-row-gap: 206px;
  }

  .nova_setting-disconnect.positioned {
    width: 100%;
  }
}

@media screen and (min-width: 1440px) {
  .text-span-5 {
    font-weight: 700;
  }

  ._2nd-screen {
    margin-bottom: 200px;
  }

  .holder-links-navbar {
    grid-column-gap: 60px;
    grid-row-gap: 60px;
  }

  .holder-navbar {
    padding-top: 30px;
  }

  .text-block-4 {
    font-size: 20px;
    line-height: 30px;
  }

  .section-2 {
    height: auto;
    overflow: auto;
  }

  .image-3 {
    width: 330px;
  }

  .image {
    margin-right: 120px;
  }

  .heading-5 {
    font-family: Archivo, sans-serif;
  }

  .heading-2 {
    font-size: 48px;
    line-height: 58px;
  }

  .heading {
    font-size: 48px;
  }

  .heading._2 {
    max-width: 540px;
  }

  .sponsor-section {
    margin-bottom: 220px;
  }

  .image-15 {
    height: auto;
    top: 660px;
  }

  .text-block-6 {
    font-size: 20px;
    line-height: 30px;
  }

  .text-block-3 {
    font-weight: 300;
  }

  .functionality-1 {
    margin-bottom: 300px;
  }

  .image-4 {
    width: 330px;
  }

  .number-screen {
    font-size: 64px;
    line-height: 70px;
  }

  .button {
    padding-left: 30px;
    padding-right: 30px;
    font-size: 16px;
    line-height: 26px;
  }

  .nova_holder-fonctions {
    top: 100px;
  }

  .nova_menu_closed {
    padding-left: 20px;
    padding-right: 20px;
  }

  .nova_holder-software-grid {
    grid-auto-columns: 1fr;
  }

  .nova_holder-software-grid-test {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    display: grid;
  }

  .nova_bouton-google, .nova_forgot-password, .nova_signup-link {
    cursor: pointer;
  }

  .nova_second-screen-onboarding {
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
  }

  .nova_button-back {
    justify-content: flex-start;
    align-items: center;
    text-decoration: none;
    display: flex;
  }
}

@media screen and (max-width: 991px) {
  .holder-form-footer {
    justify-content: center;
  }

  .holder-buttons {
    justify-content: center;
    align-items: stretch;
  }

  .image-28 {
    width: 220px;
    position: absolute;
    inset: auto auto 37% 0%;
  }

  ._2nd-screen {
    grid-column-gap: 40px;
    grid-row-gap: 40px;
    flex-flow: column;
  }

  .functionality-4 {
    flex-flow: column;
    justify-content: space-between;
    align-items: center;
  }

  .holder-links-footer {
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    flex-flow: column;
  }

  .choisir-nova {
    flex-flow: column;
    justify-content: space-between;
    align-items: center;
  }

  .gradien-div-images {
    z-index: 10;
    position: relative;
  }

  .gradien-div-images._1, .gradien-div-images._2, .gradien-div-images._3 {
    width: 90%;
  }

  .holder-navbar {
    z-index: 999;
    background-image: linear-gradient(135deg, #f9faff, #f6f6ff);
    border-bottom: 1px solid #1837ff1a;
    padding-top: 15px;
    padding-bottom: 15px;
    position: fixed;
    inset: 0% 0% auto;
  }

  .functionaity-5 {
    flex-flow: column;
    justify-content: space-between;
    align-items: center;
  }

  .image-9, .image-6 {
    width: 90%;
  }

  .image-8 {
    left: 0;
  }

  .text-block-11 {
    line-height: 28px;
  }

  .image-25 {
    width: 200px;
    position: absolute;
    inset: 26% auto auto 0%;
  }

  .functionality-3, .functionality-2 {
    flex-flow: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 200px;
  }

  .text-block-14 {
    text-transform: uppercase;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(90deg, #8e9dff, #4a50ff);
    -webkit-background-clip: text;
    background-clip: text;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
  }

  .image-18 {
    width: 100%;
  }

  .gradien-cercle.mob {
    background-image: linear-gradient(#1837ff1a, #1837ff26);
    top: -50%;
  }

  .gradien-cercle.mob.right {
    inset: -50% -90px auto auto;
  }

  .image-3 {
    display: none;
  }

  .lottie-animation {
    width: 25px;
  }

  .image-19 {
    width: 100%;
  }

  .box-holder-links {
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }

  .heading-2.nan {
    margin-left: 5%;
  }

  .heading-2.center {
    max-width: 540px;
  }

  .image-22 {
    width: 100%;
  }

  .image-26 {
    width: 190px;
    position: absolute;
    inset: 26% 0% auto auto;
  }

  .form-block.hide-mobile {
    display: none;
  }

  .form-block.mobile {
    display: block;
  }

  .heading {
    margin-bottom: 20px;
  }

  .image-27 {
    height: 80px;
    position: absolute;
    inset: 39% auto auto 0%;
  }

  .nav-menu {
    background-image: linear-gradient(135deg, #f9faff, #f6f6ff);
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    width: 100vw;
    padding-left: 5%;
    padding-right: 5%;
    display: none;
  }

  .image-11 {
    width: 150px;
  }

  .holder-brand-footer {
    justify-content: flex-start;
    align-items: center;
  }

  .holder-heading-text {
    width: 90%;
  }

  .holder-heading-text.hide-mobile {
    display: none;
  }

  .holder-heading-text.last {
    position: static;
  }

  .holder-heading-text.mobile {
    display: block;
  }

  .holder-heading-text.edit {
    grid-column-gap: 15px;
    grid-row-gap: 15px;
    width: 62%;
  }

  .image-21 {
    width: 100%;
  }

  .holder-all-footer {
    flex-flow: column;
    align-items: center;
  }

  .image-29 {
    width: 220px;
    position: absolute;
    inset: auto 0% 40% auto;
  }

  .image-17 {
    top: 66%;
  }

  .image-15 {
    top: 800px;
  }

  .section {
    padding-top: 140px;
  }

  .text-block-3 {
    margin-bottom: 60px;
  }

  .holder-box-horizontal {
    flex-flow: column;
    justify-content: space-between;
    align-items: center;
  }

  .image-20 {
    width: 100%;
  }

  .link-block {
    border-bottom: 1px solid #1837ff33;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
    text-decoration: none;
    display: flex;
  }

  .link-block._1 {
    border-top: 1px solid #1837ff33;
  }

  .link-block.last {
    margin-bottom: 20px;
  }

  .functionality-1 {
    flex-flow: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 240px;
  }

  .menu-button {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .menu-button.w--open {
    background-color: #0000;
  }

  .image-4 {
    display: none;
  }

  .section-3 {
    overflow: hidden;
  }

  .navbar-nova {
    display: none;
  }

  .avantage-box {
    z-index: 10;
    width: 90%;
    position: relative;
  }

  .avantage-box.down {
    width: 90%;
  }

  .holder-gradien-design._3 {
    top: -20%;
  }

  .holder-gradien-design._2 {
    top: -20%;
    left: 35%;
  }

  .last-section-cta {
    padding-left: 60px;
    padding-right: 60px;
  }

  .navbar {
    background-color: #0000;
    display: block;
    position: static;
  }

  ._3rd-screen {
    flex-flow: column;
    display: flex;
  }

  .nova_onboarding-section {
    flex-flow: column;
  }

  .nova_leftside-onboarding {
    width: auto;
    height: 100vh;
  }

  .nova_rightside-onboarding {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .image-28 {
    width: auto;
    bottom: 32%;
  }

  ._2nd-screen {
    z-index: 10;
    position: relative;
  }

  .text-block-10 {
    font-size: 13px;
    line-height: 23px;
  }

  .image-25 {
    width: auto;
    top: 34%;
  }

  .image-10 {
    top: -15px;
    left: -20px;
  }

  .functionality-2 {
    z-index: 10;
  }

  .section-2 {
    padding-bottom: 80px;
  }

  .image {
    margin-right: 0;
  }

  .heading-5 {
    font-size: 32px;
    line-height: 46px;
  }

  .heading-2.nan, .heading-2.center {
    margin-top: 0;
  }

  .image-26 {
    width: auto;
    top: 34%;
  }

  .heading {
    max-width: 516px;
    margin-top: 0;
    margin-bottom: 10px;
  }

  .heading._2 {
    max-width: 410px;
    margin-bottom: 0;
    font-size: 36px;
    line-height: 48px;
  }

  .image-27 {
    height: auto;
    top: 46%;
  }

  .image-24 {
    height: 20px;
  }

  .holder-heading-text.edit {
    width: 83%;
  }

  .image-29 {
    width: auto;
    bottom: 35%;
  }

  .image-17 {
    top: 67%;
  }

  .sponsor-section {
    z-index: 10;
    position: relative;
  }

  .holder-image-sponsor {
    grid-column-gap: 40px;
    grid-row-gap: 40px;
    justify-content: center;
    align-items: center;
  }

  .section {
    padding-top: 180px;
  }

  .text-block-3 {
    margin-bottom: 40px;
  }

  .section-3 {
    padding-top: 160px;
  }

  .holder-gradien-design {
    top: -30%;
  }

  .holder-gradien-design._2 {
    left: 30%;
  }

  .last-section-cta {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  ._3rd-screen {
    z-index: 10;
    position: relative;
  }

  .nova_user-abreviation._24b {
    border: 1px solid var(--dark-text-color);
    width: 24px;
    height: 24px;
  }

  .nova_user-abreviation._24b.m-4 {
    margin-left: -4px;
  }

  .nova_abvreviation-user._24 {
    font-size: 6.4px;
    line-height: 8px;
  }

  .nova_number-users {
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    background-color: var(--dark-text-color);
    border-radius: 8px;
    flex-flow: column;
    max-width: 162px;
    padding: 12px;
    display: flex;
    position: relative;
  }

  .nova_lead_editors {
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    flex-flow: column;
    display: flex;
  }

  .text-block-56 {
    color: #fffc;
    font-family: Archivo, sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
  }

  .nova_holder-icons-lead-editor {
    justify-content: flex-start;
    align-items: center;
    display: flex;
  }

  .nova_holder-down-arrow {
    justify-content: center;
    align-items: flex-end;
    display: flex;
    position: absolute;
    inset: auto 0% -7px;
  }
}

@media screen and (max-width: 479px) {
  .holder-buttons {
    flex-flow: column;
  }

  .image-28 {
    bottom: 22%;
  }

  .text-block-10 {
    margin-bottom: 25px;
    font-size: 12px;
    line-height: 21px;
  }

  .text-field {
    min-width: 190px;
    height: 45px;
    font-size: 13px;
  }

  .choisir-nova {
    margin-bottom: 140px;
  }

  .link-2 {
    font-size: 11px;
  }

  .holder-first-subtitle {
    padding-left: 10px;
    padding-right: 10px;
  }

  .image-13 {
    height: 120px;
    top: 120px;
  }

  .holder-navbar {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .image-7 {
    width: 20px;
    top: -10px;
    left: -10px;
  }

  .text-block-4 {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
  }

  .image-8 {
    height: 20px;
  }

  .submit-button {
    padding: 7px 15px;
    font-size: 14px;
    line-height: 31px;
  }

  .text-block-11 {
    font-size: 15px;
    line-height: 25px;
  }

  .image-25 {
    top: 36%;
  }

  .heading-6 {
    font-size: 20px;
    line-height: 30px;
  }

  .image-10 {
    width: 20px;
    top: -5px;
    left: -5px;
  }

  .image-30 {
    height: 15px;
  }

  .text-block-5 {
    max-width: 330px;
    font-size: 14px;
    line-height: 22px;
  }

  .section-2 {
    padding-top: 140px;
  }

  .gradien-cercle._2 {
    top: 0%;
    bottom: 0%;
    right: 0%;
  }

  .gradien-cercle._2.modified {
    right: 25%;
  }

  .heading-5 {
    margin-bottom: 10px;
    font-size: 26px;
    line-height: 34px;
  }

  .heading-4 {
    font-size: 30px;
  }

  .heading-2 {
    text-align: left;
    font-size: 24px;
    line-height: 35px;
  }

  .heading-2._2 {
    font-size: 24px;
  }

  .heading-2.center {
    text-align: center;
  }

  .image-26 {
    top: 36%;
  }

  .holder-title {
    margin-bottom: 60px;
  }

  .holder-header {
    margin-bottom: 30px;
  }

  .text-block-8 {
    font-size: 15px;
    line-height: 25px;
  }

  .heading {
    max-width: 320px;
    font-size: 3.8vh;
    line-height: 150%;
  }

  .heading._2 {
    max-width: 300px;
    font-size: 24px;
    line-height: 35px;
  }

  .image-14 {
    height: 120px;
    top: 120px;
  }

  .image-27 {
    top: 46%;
  }

  .nav-menu {
    display: inline-block;
  }

  .image-24 {
    height: 10px;
  }

  .holder-heading-text {
    justify-content: flex-start;
    align-items: flex-start;
  }

  .holder-heading-text.center {
    justify-content: flex-start;
    align-items: center;
  }

  .holder-heading-text.edit {
    width: 100%;
  }

  .image-29 {
    bottom: 25%;
  }

  .image-17 {
    height: 120px;
    top: 70%;
  }

  .holder-image-sponsor {
    grid-column-gap: 25px;
    grid-row-gap: 25px;
  }

  .image-15 {
    height: 100px;
    top: 750px;
  }

  .title-screen {
    font-size: 24px;
    line-height: 30px;
  }

  .section {
    padding-top: 120px;
  }

  .text-block-6 {
    font-size: 15px;
    line-height: 25px;
  }

  .text-block-3 {
    max-width: 320px;
    font-size: 15px;
    line-height: 22px;
  }

  .blue-line-design {
    display: none;
  }

  .holder-gradien-design._2 {
    width: 100%;
    top: -25%;
    left: 0%;
  }

  .text-block {
    margin-right: 3px;
    font-size: 9px;
  }

  .last-section-cta {
    padding-left: 30px;
    padding-right: 30px;
  }

  .text-block-9 {
    font-size: 15px;
    line-height: 25px;
  }

  .image-31, .image-32 {
    width: 80px;
  }

  .nova_leftside-onboarding {
    padding-left: 20px;
    padding-right: 20px;
  }

  .nova_onboarding-login {
    min-width: 300px;
  }

  .nova_second-screen-onboarding {
    min-width: 300px;
    min-height: 600px;
  }

  .nova_uppart-second-screen {
    grid-column-gap: 40px;
    grid-row-gap: 40px;
  }

  .nova_holder-form-button {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }
}

#w-node-f13f0c27-fc1c-1cdb-0d53-5626fe7b25ce-b88dfac5 {
  place-self: stretch stretch;
}

#w-node-_51fa8b0f-880e-55a4-f2ec-133172487257-b88dfac5 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-f13f0c27-fc1c-1cdb-0d53-5626fe7b25ce-a7811950 {
  place-self: stretch stretch;
}

#w-node-_51fa8b0f-880e-55a4-f2ec-133172487257-a7811950 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-f13f0c27-fc1c-1cdb-0d53-5626fe7b25ce-3995c619 {
  place-self: stretch stretch;
}

#w-node-_51fa8b0f-880e-55a4-f2ec-133172487257-3995c619 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-f13f0c27-fc1c-1cdb-0d53-5626fe7b25ce-1e5341d6 {
  place-self: stretch stretch;
}

#w-node-_51fa8b0f-880e-55a4-f2ec-133172487257-1e5341d6 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-f13f0c27-fc1c-1cdb-0d53-5626fe7b25ce-50763068 {
  place-self: stretch stretch;
}

#w-node-_51fa8b0f-880e-55a4-f2ec-133172487257-50763068 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-f13f0c27-fc1c-1cdb-0d53-5626fe7b25ce-25542f25 {
  place-self: stretch stretch;
}

#w-node-_51fa8b0f-880e-55a4-f2ec-133172487257-25542f25 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-f13f0c27-fc1c-1cdb-0d53-5626fe7b25ce-5981d923 {
  place-self: stretch stretch;
}

#w-node-_68d75520-d831-cfdb-0b78-4295ce0d00f7-5981d923 {
  grid-area: span 2 / span 1 / span 2 / span 1;
}

#w-node-_594c97a2-0ef0-e90a-d71b-824f2822fd05-5981d923 {
  order: 9999;
}

#w-node-f13f0c27-fc1c-1cdb-0d53-5626fe7b25ce-551839af {
  place-self: stretch stretch;
}

#w-node-_86538ff0-0907-f56c-c3a5-6540ee3d3ee2-551839af {
  grid-area: span 2 / span 1 / span 2 / span 1;
}

#w-node-f13f0c27-fc1c-1cdb-0d53-5626fe7b25ce-8635d8aa {
  place-self: stretch stretch;
}

#w-node-_86538ff0-0907-f56c-c3a5-6540ee3d3ee2-8635d8aa {
  grid-area: span 2 / span 1 / span 2 / span 1;
}

#w-node-f13f0c27-fc1c-1cdb-0d53-5626fe7b25ce-5f687110 {
  place-self: stretch stretch;
}

#w-node-_51fa8b0f-880e-55a4-f2ec-133172487257-5f687110 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}


