/* todo(3) remove once popup replaced with new design */
.la_transfer_lead_popup {
    z-index: 999;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    background-color: #0006;
    justify-content: center;
    align-items: center;
    height: 100vh;
    display: flex;
    position: fixed;
    inset: 0%;
}

.la_pop-up-send-lead {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    background-color: #fff;
    border: 1px solid #2538824d;
    border-radius: 10px;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    min-width: 400px;
    padding: 40px;
    display: flex;
    position: relative;
}

.la_text-block-40 {
    color: #253882;
    text-align: center;
    width: 216px;
    font-family: Figtree, sans-serif;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
}

.la_tf_8 {
    color: #253882;
    background-color: #fff3;
    border: 1px solid #ffffff1a;
    border-radius: 10px;
    min-width: 300px;
    margin-bottom: 0;
    font-family: Figtree, sans-serif;
    display: block;
}

.la_select-field {
    color: white;
    background-color: #253882;
    border-radius: 5px;
    margin-bottom: 0;
    font-family: Figtree, sans-serif;
    font-size: 13px;
    font-weight: 300;
}

.la_button-2 {
    background-color: #253883;
    border-radius: 5px;
    padding-left: 20px;
    padding-right: 20px;
}

.la_close-button {
    cursor: pointer;
    justify-content: flex-start;
    align-items: center;
    height: 30px;
    text-decoration: none;
    display: flex;
    position: absolute;
    inset: 20px 30px auto auto;
}

.la-popup-warning-shade {
    z-index: 999;
    background-color: #0009;
    justify-content: center;
    align-items: center;
    display: flex;
    position: fixed;
    inset: 0%;
}

.la-popup-warning-shade.unblur {
    -webkit-backdrop-filter: none;
    backdrop-filter: none;
}

.la_message-pop-up {
    background-color: #fff;
    border: 2px solid #2538824d;
    border-radius: 15px;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    width: 400px;
    height: 300px;
    padding: 30px;
    display: flex;
}

.la_image-21 {
    margin-bottom: 20px;
}


.la_text-block-47 {
    text-align: center;
    margin-bottom: 20px;
    font-family: Figtree, sans-serif;
    font-size: 20px;
    line-height: 26px;
}

.la_holder-buttons {
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    justify-content: center;
    align-items: center;
    display: flex;
}

.la_button-5 {
    text-transform: uppercase;
    background-color: #d70000;
    border-radius: 5px;
    padding: 8px 20px;
}

.la_button-5.non {
    background-color: #234cea;
}
