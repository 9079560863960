:root {
    --col-calendar-bg-color: white;
    --col-calendar-unavailable: white;
    --col-calendar-unavailable-text: var(--text-color);
    --col-calendar-available: var(--hover-selection);
    --col-calendar-available-text: var(--blue-nova);
    --col-calendar-selected: var(--blue-nova);
    --col-calendar-selected-text: white;
}

.react-calendar {
    font-family: Archivo, sans-serif;
    font-size: 14px;
    line-height: 1.125em;
    width: 400px;
    max-width: 100%;
    background-color: var(--col-calendar-bg-color);
    border-radius: 8px;
    line-height: 1.125em;
    border: 2px solid #ECECEE;
    border-radius: 8px;
}

.small_calendar .react-calendar {
    width: 300px;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

/* 
todo(1) pour cacher les jours des autres mois
.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth {
    color: white;
    background: white;
} */

.react-calendar button {
    /* margin: 0; */
    border: 0;
    outline: none;
}

.react-calendar button:enabled:hover {
    cursor: pointer;
}

.react-calendar__navigation {
    display: flex;
    height: 44px;
    margin-bottom: 1em;
}

/* top line, days container */
.react-calendar__month-view__weekdays__weekday {
    font-weight: bold;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #F6F8FA;
    color: var(--dark-text-color);
}

/* jour de la semaine */
.react-calendar__month-view__weekdays__weekday {
    text-transform: uppercase;
    font-size: 12px;
    width: 100%;
    text-align: center;
}

.react-calendar__tile {
    width: 45px;
    max-width: 45px;
    /* box-sizing: border-box; // advised by chat gpt but didnt work
    flex: 1 0 calc(14.285714285714286% - 21.14px); adjust this value as needed */
    margin: 10.57px;
    height: 45px;
    /* padding: 10px 0px; */
    background: none;
    text-align: center;
    /* font-size: 12px; */
    line-height: 16px;
    font-weight: 500;
}

.small_calendar .react-calendar__tile {
    max-width: 27px;
    height: 27px;
    margin: 7.92px;
}

.react-calendar__tile:enabled {
    background-color: var(--col-calendar-available);
    color: var(--col-calendar-available-text);
    border-radius: 6px;
}

.react-calendar__tile:enabled:hover {
    background: var(--col-calendar-selected);
    color: var(--col-calendar-selected-text);
}

.react-calendar__tile:disabled {
    background-color: var(--col-calendar-unavailable);
    color: var(--col-calendar-unavailable-text);
}

.react-calendar__navigation button {
    min-width: 44px;
    background: none;
    font-size: 16px;
    margin-top: 8px;
}


abbr[title] {
    text-decoration: none;
}

.react-calendar__month-view__days {
    width: 100%;
}

.react-calendar__tile:enabled.react-calendar__tile--active {
    background: var(--col-calendar-selected);
    color: var(--col-calendar-selected-text);
}

.react-calendar__navigation__prev2-button {
    display: none;
}

.react-calendar__navigation__next2-button {
    display: none;
}