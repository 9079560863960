/* @import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&display=swap'); */

@import url('https://fonts.googleapis.com/css2?family=Archivo:wght@400;500;600;700&display=swap');

/* .la_body {
    min-height: 100vh;
    color: black;
    font-family: DM Sans, sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 25px;
} */

/* replace text input with texarea */
.nova_commentaire-lead {
    /* Disable resizing */
    resize: none;
    /* Hide scrollbars if content doesn't fit */
    /* overflow: hidden; */
    /* Hide horizontal scrollbar */
    overflow-x: hidden;
    /* Show vertical scrollbar when needed */
    overflow-y: auto;
}

.nova_box-contact-info {
    border: 1px solid var(--border-color);
    border-radius: 8px;
}

.nova_input-info-lead {
    border: 1px solid #0000;
    border-radius: 6px;
}

.nova_holder-crm-infoleads_already_contained {
    flex-flow: column;
    justify-content: space-between;
    align-items: stretch;
    display: flex;
}

.nova_table_overflower {
    border-radius: 8px;
    /* overflow: auto; */
    overflow-y: auto;
    max-height: 70vh;
    flex-flow: column;
    justify-content: space-between;
    align-items: stretch;
    display: flex;
}

.nova_table {
    border-collapse: collapse;
    border-radius: 8px;
    white-space: nowrap;
    flex-grow: 1;
    /* Important to clip inner borders */
}

.nova_thead {
    border-bottom: 1px solid var(--border-color);
    background-color: #f9f9fd;
    height: 40px;
    position: sticky;
    top: 0;
    z-index: 1;
}

.nova_table_row {
    border-bottom: 1px solid var(--border-color);
    /* min-width: 100%; */
    height: 56px;
    transition: border .2s, background-color .2s;
    /* display: flex; */
}

.nova_table_row:hover {
    /* border-left: 1.5px solid var(--blue-nova); */
    background-color: #f9f9fd;
}

.nova_th_empty {
    border-right: 1px solid var(--border-color);
    padding-top: 8px;
    padding-bottom: 8px;
    text-align: left;
    width: auto;
    /* Ensure width is minimal */
}

.nova_th {
    border-right: 1px solid var(--border-color);
    padding-right: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 16px;
    text-align: left;
    cursor: grab;
}

.nova_th:last-child {
    border-right: none;
}

.nova_table_case {
    padding-right: 16px;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 16px;
    text-align: left;
    max-width: 250px;
    overflow: hidden;
}

.nova_table_case.no_overflow {
    overflow: visible;
}

.nova_table_case.short {
    padding-right: 0px;
    padding-left: 0px;
    flex: 1;
    justify-content: center;
    align-items: center;
}

.nova_up-bar-info2 {
    border-bottom: 1px solid var(--border-color);
}

/* Optional: Remove the last row's border if desired */
tr:last-child {
    border-bottom: none;
}

.test_full_width {
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 16px;
    display: flex;
}

@media screen and (max-width: 1200px) {
    .nova_rightside-onboarding-v2 {
        display: none;
    }
}

/* no border, mostly used for last input text of a lead card column */
.nova_holder-info-lead.noborder {
    border: none;
}

/* lead cards field input text: remove label when there is content in small screens */
@media screen and (max-width: 1000px) {
    .nova_info-text.hide_label_in_small_screen {
        display: none;
    }

    .nova_input-info-lead.hide_label_in_small_screen {
        padding-left: 5px;
    }
}

.nova_holder-last-update-dropbox {
    overflow-x: hidden;
    overflow-y: auto;
}

.nova_text-field-conversation-chatbot.no_attachment {
    border-radius: 12px;
}

/* dropdown inside chatbot forms */
.nova_relative-box-dropbox:hover>.nova_dropbox-formulaire {
    display: flex;
}

.try_aircall {
    width: 20px;
    height: 20px;
    background-color: green;
}

/* temporary, remove bottom-border in chatbot left menu */
.nova_holder-button-new-conversation.no_bottom_border {
    border-bottom: none;
}

/* PART 2: START ADMIN PART */
.nova_dropdown-item-container {
    position: absolute;
    width: 100%;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 4px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 10;
}

.nova_input-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.nova_input-field {
    border: 1px solid var(--border-color);
    border-radius: 8px;
    padding: 8px;
    cursor: pointer;
}

.nova_dropdown-selected {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    background-color: white;
}


.nova_dropdown-item {
    padding: 8px;
    cursor: pointer;
    font-size: 14px;
}

.nova_dropdown-item:hover {
    background-color: #e0e0e0;
    /* Grisé au survol */
}

.nova_dropdown-item.selected {
    background-color: #EEF4FC;
    /* Grisé si sélectionné */
}

.relative {
    position: relative;
}

.nova_dropdown-item:hover {
    background-color: var(--hover-selection);
}

/* PART 2: END ADMIN PART */
